import React, { FC } from 'react'
import { Tooltip, Text, IconButton, Heading, Box, Flex } from '@chakra-ui/react'
import { styled } from 'styled-components'
import { MdArrowOutward, MdClose, MdOpenInNew, MdOutlineCreateNewFolder } from 'react-icons/md'
import DragIcon from '../../../components/DragIcon'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import { useReduxSelector } from '../../../redux/baseStore'
import { useUserContext } from '../../../contexts/UserContext'
import { selectPendingFolderCreationPages } from '../../../redux/projectsSlice'
import { TablistPageType } from '../../../models/tablist_pages.types'

const TitleContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  margin-bottom: 6px;

  // Hovered & expanded states are managed in SmartSessionCard.tsx
  h2 {
    color: #585858;
    transition: color 0.2s;
    font-size: 14px;
    font-weight: 600;
    word-break: break-word;
    line-height: 22px;
    cursor: grab;
  }

  .hover-show-title-icons {
    transition: opacity 0.2s;
    opacity: 0;
  }

  .force-show {
    opacity: 1;
  }

  .drag-icon {
    cursor: grab;
    width: 16px;
  }
`

interface Props {
  title: string
  handleClickOpenIcon: (event: React.MouseEvent<Element, MouseEvent>) => void
  handleClickOpenInNewWindowIcon: (event: React.MouseEvent<Element, MouseEvent>) => void
  handleClickCreateProjectIcon: (event: React.MouseEvent<Element, MouseEvent>) => void
  handleClickDeleteIcon: (event: React.MouseEvent<Element, MouseEvent>) => void
  handleClickFolderCreationAddAllButton: (event: React.MouseEvent<Element, MouseEvent>) => void
  shouldHideActionIcons: boolean
  isFolderCreationModeActive?: boolean
  isSSOnboardingActive?: boolean
  pages: TablistPageType[]
}

const SmartSessionCardTitle: FC<Props> = (props) => {
  const {
    title,
    pages,
    handleClickOpenIcon,
    handleClickOpenInNewWindowIcon,
    handleClickCreateProjectIcon,
    handleClickDeleteIcon,
    handleClickFolderCreationAddAllButton,
    shouldHideActionIcons,
    isSSOnboardingActive = false,
    isFolderCreationModeActive = false,
  } = props
  const createProjectButtonTooltip = `Save as folder`
  const { userInfo } = useUserContext()
  const pendingPages = useReduxSelector(selectPendingFolderCreationPages)
  const numPendingPages = pendingPages.length
  const isPremium = !!userInfo?.is_premium
  const { projectConfig } = useFeatureFlagContext()
  const maxNumPages = projectConfig.maxNumProjectsPages
  const isProjectPageLimitReached =
    numPendingPages >= maxNumPages || numPendingPages + pages.length > maxNumPages

  return (
    <TitleContainer>
      <Flex alignItems="center">
        <Box className="hover-show-title-icons drag-icon">
          <DragIcon />
        </Box>

        <Heading as="h2">{title}</Heading>
      </Flex>

      <Flex
        height="22px"
        flex="0 0 auto"
        alignItems="center"
        marginLeft="8px"
        marginRight={isFolderCreationModeActive ? '3px' : '6px'}
        opacity={shouldHideActionIcons ? 0 : 1}
        pointerEvents={shouldHideActionIcons ? 'none' : 'auto'}
        transition="opacity 0.2s"
      >
        {isFolderCreationModeActive && (
          <Tooltip
            placement="top"
            label={
              isProjectPageLimitReached ? (
                <>
                  Tab limit reached!
                  <br />
                  {isPremium ? 'Pro' : 'Basic'} folders hold {maxNumPages} tabs (need more space?)
                  ✨
                </>
              ) : (
                'Add all tabs to folder'
              )
            }
          >
            <Flex
              bg="#CCE3F9"
              borderRadius="6px"
              color="#0071E3"
              w="67px"
              h="22px"
              flexShrink={0}
              alignItems="center"
              justifyContent="center"
              onClick={
                isProjectPageLimitReached ? undefined : handleClickFolderCreationAddAllButton
              }
              cursor={isProjectPageLimitReached ? 'not-allowed' : 'pointer'}
              opacity={isProjectPageLimitReached ? 0.4 : 1}
            >
              <Text fontSize="12px" fontWeight="500">
                + Add all
              </Text>
            </Flex>
          </Tooltip>
        )}
        {!isFolderCreationModeActive && (
          <>
            <Tooltip label={`Open in new window`} placement="top">
              <span
                className="flex-center hover-show-title-icons"
                style={{ height: '100%', cursor: 'pointer' }}
              >
                <IconButton
                  variant="outline"
                  border="none"
                  aria-label={`Open in new window`}
                  as="a"
                  icon={<MdOpenInNew color="#585858" />}
                  onClick={handleClickOpenInNewWindowIcon}
                  height="24px"
                  width="24px"
                  minWidth="24px"
                  tabIndex={-1}
                  _hover={{ background: 'inherit' }}
                />
              </span>
            </Tooltip>
            <Tooltip label={`Open`} placement="top">
              <span
                className="flex-center hover-show-title-icons"
                style={{ height: '100%', cursor: 'pointer' }}
              >
                <IconButton
                  variant="outline"
                  border="none"
                  aria-label={`Open`}
                  as="a"
                  icon={<MdArrowOutward color="#585858" />}
                  onClick={handleClickOpenIcon}
                  height="24px"
                  width="24px"
                  minWidth="24px"
                  tabIndex={-1}
                  _hover={{ background: 'inherit' }}
                />
              </span>
            </Tooltip>

            <Tooltip label={createProjectButtonTooltip} placement="top">
              <span
                className={`flex-center hover-show-title-icons ${
                  isSSOnboardingActive ? 'force-show' : ''
                }`}
                style={{ height: '100%', cursor: 'pointer' }}
              >
                <IconButton
                  variant="outline"
                  border="none"
                  aria-label={createProjectButtonTooltip}
                  as="a"
                  icon={<MdOutlineCreateNewFolder color="#585858" />}
                  onClick={handleClickCreateProjectIcon}
                  pointerEvents={shouldHideActionIcons ? 'none' : 'auto'}
                  height="24px"
                  width="24px"
                  minWidth="24px"
                  tabIndex={-1}
                  _hover={{ background: 'inherit' }}
                />
              </span>
            </Tooltip>
            <Tooltip label={'Remove session'} placement="top">
              <span
                className={`flex-center hover-show-title-icons ${
                  isSSOnboardingActive ? 'force-show' : ''
                }`}
                style={{ height: '100%', cursor: 'pointer' }}
              >
                <IconButton
                  variant="outline"
                  border="none"
                  aria-label={'Remove session'}
                  as="a"
                  icon={<MdClose color="#585858" />}
                  onClick={handleClickDeleteIcon}
                  height="24px"
                  width="24px"
                  minWidth="24px"
                  tabIndex={-1}
                  _hover={{ background: 'inherit' }}
                />
              </span>
            </Tooltip>
          </>
        )}
      </Flex>
    </TitleContainer>
  )
}

export default SmartSessionCardTitle
