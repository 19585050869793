import React, { FC } from 'react'
import GuidedAutoSaveOnboardingModalV3 from '../../guidedAutoSave/GuidedAutoSaveOnboardingModalV3'

interface Props {
  onNext: () => void
}
const OnboardingGuidedAutoSaveDialogV3: FC<Props> = (props) => {
  const { onNext } = props

  return <GuidedAutoSaveOnboardingModalV3 phaseNum={9} numPhases={10} onNext={onNext} />
}
export default OnboardingGuidedAutoSaveDialogV3
