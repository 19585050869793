import React, { FC, useCallback, useEffect } from 'react'
import { TablistPageType } from '../../../models/tablist_pages.types'
import PutasideTabController from '../PutasideTabList/PutasideTabController'
import { TABLIST_AREA_NAME_ENUM } from '../PutasideTabList/PutasideTabList'
import { Box, Flex } from '@chakra-ui/react'
import { hashString } from '../../../utils/utils'
import { MoveTablistPageInfoType } from '../moveItemMenu/moveItemMenu.types'
import { SelectedTablistPagesType } from '../../../redux/selectedPagesSlice'
import { PUTASIDE_TAB_VIEW_STYLE_VARIANT_ENUM } from '../PutasideTabList/PutasideTabView'
import SavedTabsTopicGroupCardTitle from './SavedTabsTopicGroupCardTitle'
import { useDrag } from 'react-dnd'
import { DND_ITEM_ENUM } from '../../../models/dnd.types'
import { DnDItemTablistPagesPayload } from '../../../models/dnd.types'
import { setIsTablistPageDragging } from '../../../redux/dndSlice'
import { addPendingFolderCreationPages } from '../../../redux/projectsSlice'
import { useReduxDispatch } from '../../../redux/baseStore'
import { useUserContext } from '../../../contexts/UserContext'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import { sendMessageToExtension } from '../../../webapp/utils/externalMessaging'
import { getEmptyImage } from 'react-dnd-html5-backend'

const TAB_GROUP_COLOR_MAP: {
  [key in chrome.tabGroups.Color]: string
} = {
  grey: 'rgb(85,88,93)',
  blue: 'rgb(43,99,224)',
  red: 'rgb(199,57,39)',
  yellow: 'rgb(239,166,39)',
  green: 'rgb(44,115,53)',
  pink: 'rgb(190,41,120)',
  purple: 'rgb(143,56,237)',
  cyan: 'rgb(37,110,119)',
  orange: 'rgb(239,139,64)',
}

const getRandomColorFromTitle = (title: string) => {
  const colorIndex = Math.abs(hashString(title)) % Object.values(TAB_GROUP_COLOR_MAP).length
  const color = Object.values(TAB_GROUP_COLOR_MAP)[colorIndex]
  return color
}

interface Props {
  groupId: number
  index: number
  pages: TablistPageType[]
  title: string
  lastVisitedTime: number
  numTotalResults: number
  highlightedPageIds?: string[]
  selectedPages: SelectedTablistPagesType
  showAiSuggestion?: boolean
  aiSuggestionReason?: string
  pendingFolderCreationPages: TablistPageType[]
  handleProjectLimitError: () => void
  isProjectLimitReached: boolean
  isFolderCreationModeActive: boolean
  handleCreateProject: (title: string, tablistPages: TablistPageType[]) => Promise<void>
  onDeleteTablistPage: (params: { page: TablistPageType; index: number }) => Promise<void>
  onOpenPageMoveMenu: (params: { pageInfo: MoveTablistPageInfoType }) => void
  onPageSelected: (params: {
    clickedItemId: string
    isShiftKey: boolean
    isCtrlOrCmdKey: boolean
    isCurrentlySelected: boolean
    extraAnalyticsProps?: Record<string, unknown>
  }) => void
  removePageSelection: (params: { pageId: string }) => void
  shouldHideCardActionIcons: boolean
}

const SavedTabsTopicGroupCard: FC<Props> = (props) => {
  const {
    pages,
    title,
    numTotalResults,
    highlightedPageIds,
    selectedPages,
    showAiSuggestion,
    aiSuggestionReason,
    pendingFolderCreationPages,
    handleProjectLimitError,
    isProjectLimitReached,
    isFolderCreationModeActive,
    handleCreateProject,
    onDeleteTablistPage,
    onOpenPageMoveMenu,
    onPageSelected,
    removePageSelection,
    shouldHideCardActionIcons,
  } = props
  const { captureAnalytics } = useUserContext()
  const dispatch = useReduxDispatch()

  const color = getRandomColorFromTitle(title)

  const handleClickOpen = useCallback(
    async (newWindow: boolean) => {
      const urls = pages.map((page) => page.url)

      captureAnalytics(
        `saved_tabs_topic_group_card:${
          newWindow ? 'open_pages_in_new_window_icon_click' : 'open_pages_icon_click'
        }`,
        {
          pages,
          title,
          urls,
        },
      )

      await sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.OPEN_TABLIST_PAGES, {
        pages,
        newWindow,
      })
    },
    [captureAnalytics, pages, title],
  )

  const handleClickOpenInNewWindowIcon = useCallback(
    async (event: React.MouseEvent<Element, MouseEvent>) => {
      event.stopPropagation()
      await handleClickOpen(true)
    },
    [handleClickOpen],
  )

  const handleClickOpenIcon = useCallback(
    async (event: React.MouseEvent<Element, MouseEvent>) => {
      event.stopPropagation()
      await handleClickOpen(false)
    },
    [handleClickOpen],
  )

  const handleClickFolderCreationAddAllButton = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>) => {
      event.stopPropagation()
      dispatch(addPendingFolderCreationPages({ pages: pages }))
      captureAnalytics('saved_tabs_topic_group_card:folder_creation_add_all_button_click', {
        pages,
        title,
      })
    },
    [captureAnalytics, dispatch, pages, title],
  )

  const handleClickCreateProject = useCallback(
    async (event: React.MouseEvent<Element, MouseEvent>) => {
      event.stopPropagation()
      captureAnalytics('saved_tabs_topic_group_card:create_project_icon_click', {
        pages,
        title,
        isProjectLimitReached,
      })

      if (isProjectLimitReached) {
        handleProjectLimitError()
      } else {
        await handleCreateProject(title, pages)
      }
    },
    [
      captureAnalytics,
      handleCreateProject,
      handleProjectLimitError,
      isProjectLimitReached,
      pages,
      title,
    ],
  )

  // const handleClickDeleteCardIcon = useCallback(
  //   async (event: React.MouseEvent<Element, MouseEvent>) => {
  //     event.stopPropagation()
  //     await onDeleteTablistPages({ pages })
  //   },
  //   [onDeleteTablistPages, pages],
  // )

  const [{ isDraggingThis }, connectDragSource, connectDragPreview] = useDrag(
    () => ({
      type: DND_ITEM_ENUM.TABLIST_PAGES,
      collect: (monitor) => ({
        isDraggingThis: monitor.isDragging(),
      }),
      end: () => dispatch(setIsTablistPageDragging(false)),
      item: () => {
        dispatch(setIsTablistPageDragging(true))
        const payload: DnDItemTablistPagesPayload = {
          type: DND_ITEM_ENUM.TABLIST_PAGES,
          groupName: title,
          payloads: pages.map((page, idx) => ({
            type: DND_ITEM_ENUM.TABLIST_PAGE,
            page,
            index: idx, //TODO: I don't think this is right
          })),
        }
        return payload
      },
    }),
    [dispatch, pages, title],
  )

  useEffect(() => {
    connectDragPreview(getEmptyImage(), { captureDraggingState: true })
  }, [connectDragPreview])

  return (
    <Box
      ref={connectDragSource}
      w="100%"
      padding="8px 14px 4px 0px"
      transition="all 0.2s ease-in-out"
      opacity={isDraggingThis ? 0.5 : 1}
      border="1px solid transparent"
      borderRadius="8px"
      _hover={{
        backgroundColor: '#fff',
        borderColor: '#d5d5d5',
        '.hover-show-title-icons': {
          opacity: 1,
        },
      }}
    >
      <SavedTabsTopicGroupCardTitle
        title={title}
        color={color}
        pages={pages}
        handleClickOpenIcon={handleClickOpenIcon}
        handleClickOpenInNewWindowIcon={handleClickOpenInNewWindowIcon}
        handleClickCreateProjectIcon={handleClickCreateProject}
        handleClickFolderCreationAddAllButton={handleClickFolderCreationAddAllButton}
        handleClickDeleteIcon={undefined}
        shouldHideActionIcons={shouldHideCardActionIcons}
        isFolderCreationModeActive={isFolderCreationModeActive}
        showAiSuggestion={showAiSuggestion}
        aiSuggestionReason={aiSuggestionReason}
      />
      <Flex>
        <Box position="relative" ml="22.5px" mr="10px" mb="12px">
          <Box
            position="absolute"
            bottom="3px"
            left="0"
            h="calc(100% + 2px)"
            borderStyle="solid"
            borderWidth="0 0 2px 2px"
            borderRadius="8px 8px 0px 4px"
            borderColor={color}
          />
          <Box
            position="absolute"
            bottom="0"
            left="3px"
            w="5px"
            borderStyle="solid"
            borderWidth="0 0 2px 2px"
            borderRadius="0px 8px 8px 4px"
            borderColor={color}
          />
          <Box
            position="absolute"
            bottom="0"
            left="0"
            w="4px"
            h="4px"
            borderStyle="solid"
            borderWidth="0 0 2px 2px"
            borderRadius="0px 0px 0px 8px"
            borderColor={color}
          />
        </Box>

        <Box w="calc(100% - 33px)">
          {pages.map((p, idx) => {
            const isTabAboveSelected = idx > 0 ? pages[idx - 1].id in selectedPages : false
            const isTabBelowSelected =
              idx < pages.length - 1 ? pages[idx + 1].id in selectedPages : false

            return (
              <PutasideTabController
                key={p.id}
                id={p.id}
                page={p}
                onDeleteTablistPage={onDeleteTablistPage}
                shouldShowDeleteIcon={true}
                queryValue={''}
                index={idx}
                numTotalResults={numTotalResults}
                areaName={TABLIST_AREA_NAME_ENUM.RecentlySaved}
                styleVariant={PUTASIDE_TAB_VIEW_STYLE_VARIANT_ENUM.TOPIC_GROUP}
                showAiSuggestion={!showAiSuggestion && !!p.ai_suggestion}
                onOpenMoveMenu={onOpenPageMoveMenu}
                isHighlighted={highlightedPageIds && highlightedPageIds.includes(p.id)}
                isSelected={p.id in selectedPages}
                isTabAboveSelected={isTabAboveSelected}
                isTabBelowSelected={isTabBelowSelected}
                onSelected={onPageSelected}
                removeSelection={removePageSelection}
                shouldShowFolderCreationAddIcon={isFolderCreationModeActive}
                isPendingInFolderCreation={pendingFolderCreationPages.some(
                  (e) => e.instance_id === p.instance_id,
                )}
              />
            )
          })}
        </Box>
      </Flex>
    </Box>
  )
}

export default SavedTabsTopicGroupCard
