import React, { FC } from 'react'
import OnboardingStepTemplate from '../OnboardingStepTemplate'
import { Box, Text } from '@chakra-ui/react'
import { styled } from 'styled-components'
import { useReduxSelector } from '../../../../redux/baseStore'
import { selectSessionSessions } from '../../../../redux/sessionSlice'
import { useUserContext } from '../../../../contexts/UserContext'

const Container = styled(Box)`
  .large-text,
  .large-text * {
    font-size: 28px;
    font-weight: 500;
    line-height: 40px;
    color: #000;
    letter-spacing: -1px;

    @media (max-width: 1200px) {
      /* Screens narrower than 1200px */
      font-size: 24px;
      line-height: 36px;
    }
  }
`

interface Props {
  onNext: () => void
}
const OnboardingNoSessionsV3Dialog: FC<Props> = (props) => {
  const { onNext } = props
  const sessions = useReduxSelector(selectSessionSessions)
  const { captureAnalytics } = useUserContext()

  return (
    <OnboardingStepTemplate
      phaseNum={4}
      numPhases={6}
      bodyContent={
        <>
          <Container>
            {sessions && sessions.length > 0 ? (
              <>
                <Text className="large-text">
                  We even grouped the ones you&apos;ll need soon into{' '}
                  <span style={{ textDecoration: 'underline' }}>Sessions</span>.
                </Text>
                <Text mt="18px" fontWeight="400" fontSize="16px" color="#585858">
                  Think of Sessions like brain-friendly bookmarks - they keep your tabs organized
                  exactly how you were using them.
                </Text>
              </>
            ) : (
              <Text className="large-text">
                Soon, we&apos;ll start to group related ones together into{' '}
                <span style={{ textDecoration: 'underline' }}>Sessions</span> to keep your tabs
                organized exactly how you were using them.
              </Text>
            )}
          </Container>
        </>
      }
      buttonText={`Okay`}
      onButtonClick={() => {
        if (!sessions || sessions.length === 0) {
          captureAnalytics('onboarding_tutorial:skipped_sessions')
        }
        onNext()
      }}
      delayButtonActivation={false}
    />
  )
}
export default OnboardingNoSessionsV3Dialog
