import { useEffect, useState } from 'react'
import { useLazyGetSmartSessionsWithOpenTabsV2Query } from '../../../redux/services/skeema/smart_sessions.endpoints'
import { SmartSessionType } from '../../../models/smart_sessions.types'
import { useLazyGetOpenTabsQuery } from '../../../webapp/redux/extension'

function useSmartSessionPoll(
  pollingInterval: number = 5000,
  isEnabled: boolean,
): SmartSessionType[] {
  const [sessions, setSessions] = useState<SmartSessionType[]>([])
  const [triggerSmartSessionsQuery] = useLazyGetSmartSessionsWithOpenTabsV2Query({
    refetchOnFocus: false,
  })
  const [triggerOpenTabsQuery] = useLazyGetOpenTabsQuery({
    refetchOnFocus: false,
  })

  useEffect(() => {
    if (!isEnabled) {
      return
    }

    const fetchSessions = async () => {
      console.log('Polling Smart Sessions')
      const openTabs = (await triggerOpenTabsQuery(undefined)).data
      const result = await triggerSmartSessionsQuery({ openTabs })
      if (result.data && result.data.sessions) {
        setSessions(result.data.sessions)
      } else {
        setSessions([])
      }
    }

    if (sessions && sessions.length === 0) {
      const intervalId = setInterval(fetchSessions, pollingInterval)
      return () => {
        clearInterval(intervalId)
      }
    }
  }, [pollingInterval, triggerSmartSessionsQuery, triggerOpenTabsQuery, sessions, isEnabled])

  return sessions
}

export default useSmartSessionPoll
