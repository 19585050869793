import React, { FC, useCallback, useEffect, useState } from 'react'
import { Collapse, Box, Flex, Heading, Icon } from '@chakra-ui/react'
import { TablistPageType } from '../../../models/tablist_pages.types'
import { styled } from 'styled-components'
import { useUserContext } from '../../../contexts/UserContext'
import { useReduxDispatch, useReduxSelector } from '../../../redux/baseStore'
import { selectSearchQueryValue } from '../../../redux/searchSlice'
import { cx, css } from '@emotion/css'
import SearchResultsReplacementWrapper from '../search/SearchResultsReplacementWrapper'
import { MdOutlineInbox, MdOutlineViewAgenda } from 'react-icons/md'
import SavedForLaterList from './SavedForLaterList'
import { GLOBAL_WINDOW_MESSAGE_ENUM, onGlobalWindowMessage } from '../../../utils/utils'
import { clearSelectedPages } from '../../../redux/selectedPagesSlice'
import { useLocation } from 'react-router-dom'
import SmartSessionDashboard from '../SmartSessionDashboard'
import OpenTabsSection from './OpenTabsSection'
import MostUsedTabsSection from './MostUsedTabsSection'

export interface SelectedTablistPageType {
  [id: string]: TablistPageType
}

export enum TABLIST_AREA_NAME_ENUM {
  MostUsed = 'most_used_tabs',
  Open = 'open_tabs',
  RecentlySaved = 'other_recently_used_tabs',
  SearchResults = 'search_results',
  SmartSessions = 'smart_sessions',
  FeedHistory = 'feed_history',
  FolderCreation = 'folder_creation',
}

const SectionContainer = styled.div`
  margin-bottom: 16px;
`

const onboardingPrepAnimationClassName = css`
  transition: opacity 2000ms ease-in-out 500ms;
  opacity: 0;
`

const onboardingAnimationClassName = css`
  opacity: 1;
`

export enum PUTASIDE_TABLIST_VIEW_ENUM {
  SAVED_TABS = 'SAVED_TABS',
  SESSIONS = 'SESSIONS',
}

interface Props {
  defaultView: PUTASIDE_TABLIST_VIEW_ENUM
  isOnboardingTutorialEnabled?: boolean
  isSmartSessionOnboardingActive?: boolean
  isSavedTabsOnboardingStepActive?: boolean
  hideOpenTabs?: boolean
  noAnimation?: boolean
}

const PutasideTabList: FC<Props> = (props) => {
  const {
    defaultView,
    isOnboardingTutorialEnabled = false,
    isSmartSessionOnboardingActive = false,
    isSavedTabsOnboardingStepActive = false,
    hideOpenTabs = false,
    noAnimation = false,
  } = props
  const { captureAnalytics } = useUserContext()
  const dispatch = useReduxDispatch()

  const [_activeView, setActiveView] = useState<PUTASIDE_TABLIST_VIEW_ENUM>(defaultView)
  const activeView = isSmartSessionOnboardingActive
    ? PUTASIDE_TABLIST_VIEW_ENUM.SESSIONS
    : isSavedTabsOnboardingStepActive
      ? PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS
      : _activeView

  const { hash } = useLocation()
  const shouldRetrieveHighlightedPages = hash === '#highlighted_tabs'
  useEffect(() => {
    if (shouldRetrieveHighlightedPages) {
      setActiveView(PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS)
    }
  }, [shouldRetrieveHighlightedPages])

  useEffect(() => {
    return () => {
      dispatch(clearSelectedPages())
    }
  }, [dispatch, activeView])

  const queryValue = useReduxSelector(selectSearchQueryValue)

  const isSearchingActive = queryValue.trim().length > 0
  const isResultsModeEnabled = isSearchingActive

  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false)
  useEffect(() => {
    setTimeout(() => {
      setShouldAnimate(isOnboardingTutorialEnabled)
    }, 500)
  }, [isOnboardingTutorialEnabled])

  const handleClickSessionsTab = useCallback(() => {
    setActiveView((prev) => {
      if (prev !== PUTASIDE_TABLIST_VIEW_ENUM.SESSIONS) {
        captureAnalytics('putaside_tab_list:sessions_tab_click')
      }
      return PUTASIDE_TABLIST_VIEW_ENUM.SESSIONS
    })
  }, [captureAnalytics])

  const handleClickAllSavedTabsTab = useCallback(() => {
    setActiveView((prev) => {
      if (prev !== PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS) {
        captureAnalytics('putaside_tab_list:all_saved_tabs_tab_click')
      }
      return PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS
    })
  }, [captureAnalytics])

  useEffect(() => {
    return onGlobalWindowMessage(GLOBAL_WINDOW_MESSAGE_ENUM.SWITCH_TO_SAVED_TABS_VIEW, () => {
      setActiveView(PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS)
    })
  }, [setActiveView])

  return (
    <Box
      className={cx({
        [onboardingPrepAnimationClassName]: isOnboardingTutorialEnabled && !noAnimation,
        [onboardingAnimationClassName]: shouldAnimate,
        scrollbars: isOnboardingTutorialEnabled,
      })}
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      overflowY={isOnboardingTutorialEnabled ? 'auto' : 'hidden'}
      borderRadius={isOnboardingTutorialEnabled ? '16px 16px 0px 0px' : undefined}
      backgroundColor={isOnboardingTutorialEnabled ? '#f6f6f6' : undefined}
    >
      <Box p="24px 16px">
        <SearchResultsReplacementWrapper>
          <Collapse in={!isResultsModeEnabled} animateOpacity>
            <MostUsedTabsSection
              isSmartSessionOnboardingActive={isSmartSessionOnboardingActive}
              isCollapsed={!isSavedTabsOnboardingStepActive}
            />
            {!hideOpenTabs ? (
              <OpenTabsSection
                isSmartSessionOnboardingActive={isSmartSessionOnboardingActive}
                isCollapsed={!isSavedTabsOnboardingStepActive}
              />
            ) : null}

            <SectionContainer>
              <Box mb="12px">
                <Flex m="0px 0px" alignItems="center" position="relative" zIndex={2}>
                  <Box
                    cursor="pointer"
                    p="10px 10px 0 10px"
                    pointerEvents={isSavedTabsOnboardingStepActive ? 'none' : 'auto'}
                    opacity={isSavedTabsOnboardingStepActive ? 0.2 : 1}
                    onClick={handleClickSessionsTab}
                  >
                    <Flex
                      p="0 2px 6px 2px"
                      alignItems="center"
                      borderBottom="2px solid"
                      borderBottomColor={
                        activeView === PUTASIDE_TABLIST_VIEW_ENUM.SESSIONS ? '#000' : 'transparent'
                      }
                      color={
                        activeView === PUTASIDE_TABLIST_VIEW_ENUM.SESSIONS ? '#000' : '#585858'
                      }
                      _hover={
                        activeView === PUTASIDE_TABLIST_VIEW_ENUM.SESSIONS
                          ? undefined
                          : { borderBottomColor: '#585858' }
                      }
                      transition="all 0.2s"
                      gap="6px"
                    >
                      <Icon as={MdOutlineViewAgenda} width="24px" height="24px" />
                      <Heading
                        as="h2"
                        color="inherit"
                        fontSize="16px"
                        fontWeight={600}
                        lineHeight="24px"
                      >
                        Sessions
                      </Heading>
                    </Flex>
                  </Box>
                  <Box
                    cursor="pointer"
                    p="10px 10px 0 10px"
                    pointerEvents={isSavedTabsOnboardingStepActive ? 'none' : 'auto'}
                    onClick={handleClickAllSavedTabsTab}
                  >
                    <Flex
                      p="0 2px 6px 2px"
                      alignItems="center"
                      borderBottom="2px solid"
                      borderBottomColor={
                        activeView === PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS
                          ? '#000'
                          : 'transparent'
                      }
                      color={
                        activeView === PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS ? '#000' : '#585858'
                      }
                      _hover={
                        activeView === PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS
                          ? undefined
                          : { borderBottomColor: '#585858' }
                      }
                      transition="all 0.2s"
                      gap="6px"
                    >
                      <Icon as={MdOutlineInbox} width="24px" height="24px" />
                      <Heading
                        as="h2"
                        color="inherit"
                        fontSize="16px"
                        fontWeight={600}
                        lineHeight="24px"
                      >
                        All saved tabs
                      </Heading>
                    </Flex>
                  </Box>
                </Flex>

                <Box
                  borderBottom="1px solid #D5D5D5"
                  margin="0px 8px 0px 10px"
                  position="relative"
                  top="-1px"
                  zIndex={1}
                />
              </Box>

              <Collapse in={activeView === PUTASIDE_TABLIST_VIEW_ENUM.SESSIONS} animateOpacity>
                <SmartSessionDashboard isSSOnboardingActive={isSmartSessionOnboardingActive} />
              </Collapse>

              <Collapse in={activeView === PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS} animateOpacity>
                <SavedForLaterList />
              </Collapse>
            </SectionContainer>
          </Collapse>
        </SearchResultsReplacementWrapper>
      </Box>
    </Box>
  )
}

export default PutasideTabList
