import React, { FC } from 'react'
import { ONBOARDING_TUTORIAL_STEPS } from './onboarding.types'
import OnboardingTablistDialog from './OnboardingTablistDialog'
import OnboardingPinTabsDialog from './OnboardingPinTabsDialog'
import OnboardingAutoSaveConfigDialog from './OnboardingAutoSaveConfigDialog'
import OnboardingIntroDialog from './OnboardingIntroDialog'
import OnboardingPrefaceDialog from './OnboardingPrefaceDialog'
import OnboardingStatsDialog from './OnboardingStatsDialog'
import OnboardingConclusionDialog from './OnboardingConclusionDialog'
import OnboardingGuidedAutoSaveDialog from './OnboardingGuidedAutoSaveDialog'
import OnboardingFolderCreatedDialog from './OnboardingFolderCreatedDialog'
import OnboardingNewFolderButtonDialog from './OnboardingNewFolderButtonDialog'
import OnboardingDraftProjectsDialog from './OnboardingDraftProjectsDialog'
import OnboardingPrefaceV3Dialog from './V3/OnboardingPrefaceV3Dialog'
import OnboardingHowSkipperWorksV3Dialog from './V3/OnboardingHowSkipperWorksV3Dialog'
import OnboardingGettingStartedV3Dialog from './V3/OnboardingGettingStartedV3Dialog'
import OnboardingStatsV3Dialog from './V3/OnboardingStatsV3Dialog'
import OnboardingSessionsV3Dialog from './V3/OnboardingSessionsV3Dialog'
import OnboardingGuidedAutoSaveDialogV3 from './V3/OnboardingGuidedAutoSaveDialogV3'
import OnboardingGuidedOrAutoSavePickerDialogV3 from './V3/OnboardingGuidedOrAutoSavePickerDialogV3'
import OnboardingConclusionV3Dialog from './V3/OnboardingConclusionV3Dialog'
import OnboardingQuestionnaireDialog from './OnboardingQuestionnaireDialog'
import OnboardingSessionsOrganizeV3Dialog from './V3/OnboardingSessionsOrganizeV3Dialog'
import OnboardingSessionsCleanUpV3Dialog from './V3/OnboardingSessionsCleanUpV3Dialog'
import OnboardingSessionsCompleteV3Dialog from './V3/OnboardingSessionsCompleteV3Dialog'
import OnboardingBreatheEasyV3Dialog from './V3/OnboardingBreatheEasyV3Dialog'

interface Props {
  currentStep: ONBOARDING_TUTORIAL_STEPS
  onNext: () => void
  currentStepIdx: number
  numSteps: number
}

const OnboardingDialogs: FC<Props> = (props) => {
  const { onNext, currentStep, currentStepIdx, numSteps } = props

  if (currentStep === ONBOARDING_TUTORIAL_STEPS.Preface) {
    return <OnboardingPrefaceDialog onNext={onNext} />
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.PrefaceV3) {
    return <OnboardingPrefaceV3Dialog onNext={onNext} />
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.HowSkipperWorks) {
    return <OnboardingHowSkipperWorksV3Dialog onNext={onNext} />
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.GettingStarted) {
    return <OnboardingGettingStartedV3Dialog onNext={onNext} />
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.BreatheEasy) {
    return <OnboardingBreatheEasyV3Dialog onNext={onNext} />
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.Stats) {
    return (
      <OnboardingStatsDialog phaseNum={currentStepIdx + 1} numPhases={numSteps} onNext={onNext} />
    )
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.StatsV3) {
    return <OnboardingStatsV3Dialog onNext={onNext} />
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.Sessions) {
    return <OnboardingSessionsV3Dialog onNext={onNext} />
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.SessionsOrganize) {
    return <OnboardingSessionsOrganizeV3Dialog onNext={onNext} />
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.SessionsCleanUp) {
    return <OnboardingSessionsCleanUpV3Dialog onNext={onNext} />
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.SessionsComplete) {
    return <OnboardingSessionsCompleteV3Dialog onNext={onNext} />
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.Intro) {
    return (
      <OnboardingIntroDialog phaseNum={currentStepIdx + 1} numPhases={numSteps} onNext={onNext} />
    )
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.TabList) {
    return (
      <OnboardingTablistDialog phaseNum={currentStepIdx + 1} numPhases={numSteps} onNext={onNext} />
    )
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.PinTabs) {
    return (
      <OnboardingPinTabsDialog phaseNum={currentStepIdx + 1} numPhases={numSteps} onNext={onNext} />
    )
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.GuidedAutoSave) {
    return (
      <OnboardingGuidedAutoSaveDialog
        phaseNum={currentStepIdx + 1}
        numPhases={numSteps}
        onNext={onNext}
      />
    )
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.GuidedAutoSaveV3) {
    return <OnboardingGuidedAutoSaveDialogV3 onNext={onNext} />
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.AutoSaveConfig) {
    return (
      <OnboardingAutoSaveConfigDialog
        phaseNum={currentStepIdx + 1}
        numPhases={numSteps}
        onNext={onNext}
      />
    )
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.GuidedOrAutoSavePickerV3) {
    return <OnboardingGuidedOrAutoSavePickerDialogV3 onNext={onNext} />
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.Conclusion) {
    return (
      <OnboardingConclusionDialog
        phaseNum={currentStepIdx + 1}
        numPhases={numSteps}
        onNext={onNext}
      />
    )
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.ConclusionV3) {
    return <OnboardingConclusionV3Dialog onNext={onNext} />
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.NewFolderButton) {
    return <OnboardingNewFolderButtonDialog phaseNum={currentStepIdx + 1} numPhases={numSteps} />
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.FolderCreated) {
    return (
      <OnboardingFolderCreatedDialog
        phaseNum={currentStepIdx + 1}
        numPhases={numSteps}
        onNext={onNext}
      />
    )
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.DraftProjects) {
    return (
      <OnboardingDraftProjectsDialog
        phaseNum={currentStepIdx + 1}
        numPhases={numSteps}
        onNext={onNext}
      />
    )
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.FolderCreation) {
    return <></>
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.Questionnaire) {
    return <OnboardingQuestionnaireDialog onNext={onNext} />
  }
}

export default OnboardingDialogs
