import React, { FC, useEffect, useMemo } from 'react'
import OnboardingStepTemplate from '../OnboardingStepTemplate'
import { Box, UnorderedList, ListItem, Text, Spinner } from '@chakra-ui/react'
import { styled } from 'styled-components'
import { useGetOpenTabsQuery } from '../../../../webapp/redux/extension'
import { getS } from '../../../../utils/utils'
import { useReduxDispatch } from '../../../../redux/baseStore'
import { projectsApi } from '../../../../redux/services/skeema/projects.endpoints'

const Container = styled(Box)`
  * {
    font-size: 28px;
    font-weight: 500;
    line-height: 40px;
    color: #000;
    letter-spacing: -1px;

    @media (max-width: 1200px) {
      /* Screens narrower than 1200px */
      font-size: 24px;
      line-height: 36px;
    }
  }
`

interface Props {
  onNext: () => void
}
const OnboardingStatsV3Dialog: FC<Props> = (props) => {
  const { onNext } = props
  const dispatch = useReduxDispatch()

  useEffect(() => {
    dispatch(projectsApi.util.invalidateTags(['ActiveProjects']))
  }, [dispatch])

  const { data: tabs, isLoading } = useGetOpenTabsQuery(undefined, { pollingInterval: 1000 })

  const [numTabs, numTabGroups, numWindows] = useMemo(() => {
    if (!tabs) {
      return [undefined, undefined, undefined, undefined]
    }

    const numTabs = tabs.length
    const numTabGroups = new Set(tabs.map((t) => t.group_id)).size - 1
    const numWindows = new Set(tabs.map((t) => t.window_id)).size

    return [numTabs, numTabGroups, numWindows]
  }, [tabs])

  return (
    <OnboardingStepTemplate
      phaseNum={2}
      numPhases={6}
      bodyContent={
        <>
          {isLoading && (
            <div className="flex-center" style={{ width: '100%', marginTop: '32px' }}>
              <Spinner color="blue.500" size="lg" speed="1s" />
            </div>
          )}
          {!isLoading && (
            <Container>
              <Text mb="4px">Here’s what your brain is juggling right now:</Text>
              <UnorderedList mb="16px" marginInlineStart="1.5em">
                <ListItem>
                  {numTabs} open tab{getS(numTabs)}
                </ListItem>
                {(numTabGroups ?? 0) > 0 && (
                  <ListItem>{`${numTabGroups} tab group${getS(numTabGroups)}`}</ListItem>
                )}
                {(numWindows ?? 0) > 0 && (
                  <ListItem>
                    {numWindows} window{getS(numWindows)}
                  </ListItem>
                )}
              </UnorderedList>
              <Text>Let&apos;s help you focus on just what you need.</Text>
            </Container>
          )}
        </>
      }
      buttonText={`Okay`}
      onButtonClick={onNext}
      delayButtonActivation={true}
      delayLabel="Just a moment…"
    />
  )
}
export default OnboardingStatsV3Dialog
