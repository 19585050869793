import { SmartSessionType } from '../models/smart_sessions.types'

export const ONBOARDING_ORGANIZED_SESSION_ID_KEY = 'SkipperOrganizedSessionId'
export const ONBOARDING_SKIPPED_CLEANUP_KEY = 'SkipperSessionCleanupSkipped'

const MINIMUM_SESSIONS = 1

export const hasValidSessions = (sessions: SmartSessionType[] | undefined): boolean => {
  if (!sessions) {
    return false
  }

  if (!Array.isArray(sessions)) {
    return false
  }

  if (sessions.length <= MINIMUM_SESSIONS) {
    return false
  }

  return true
}
