import React, { FC } from 'react'
import { Box, Button, Divider, Heading, Select } from '@chakra-ui/react'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import { sendMessageToExtension } from '../../../extension/pages/popup/utils/messaging'
import { CHROME_STORAGE_LOCAL_KEYS } from '../../../extension/models/extensionStorage.types'
import { WEBAPP_LOCAL_STORAGE_KEYS } from '../../../models/browserStorage.types'
import { WEBAPP_BASE_URL } from '../../../constants'

const getOptionsForGuidedAutoSaveFrequencyThreshold = () => {
  const options = [
    { key: 600000, value: 600000, label: '10 min' },
    { key: 1800000, value: 1800000, label: '30 min' },
    { key: 3600000, value: 3600000, label: '1 hour' },
    { key: 7200000, value: 7200000, label: '2 hours' },
    { key: 14400000, value: 14400000, label: '4 hours' },
    { key: 28800000, value: 28800000, label: '8 hours' },
    { key: 43200000, value: 43200000, label: '12 hours' },
    { key: 86400000, value: 86400000, label: 'Daily after 6am' },
  ]

  return options.map((option) => (
    <option key={option.key} value={option.value}>
      {option.label}
    </option>
  ))
}

const getOptionsForGuidedAutoSaveInactivityThreshold = () => {
  const options = [
    { key: 60000, value: 60000, label: '1 min' },
    { key: 300000, value: 300000, label: '5 min' },
    { key: 600000, value: 600000, label: '10 min' },
    { key: 1800000, value: 1800000, label: '30 min' },
    { key: 3600000, value: 3600000, label: '1 hour' },
    { key: 7200000, value: 7200000, label: '2 hours' },
    { key: 14400000, value: 14400000, label: '4 hours' },
    { key: 28800000, value: 28800000, label: '8 hours' },
  ]

  return options.map((option) => (
    <option key={option.key} value={option.value}>
      {option.label}
    </option>
  ))
}

interface Props {
  guidedAutoSaveFrequencyThreshold?: number
  guidedAutoSaveInactivityThreshold?: number
  onChangeGuidedAutoSaveFrequencyThreshold: (value: number) => void
  onChangeGuidedAutoSaveInactivityThreshold: (value: number) => void
}

const InternalSettingsContent: FC<Props> = (props) => {
  const {
    guidedAutoSaveFrequencyThreshold,
    guidedAutoSaveInactivityThreshold,
    onChangeGuidedAutoSaveFrequencyThreshold,
    onChangeGuidedAutoSaveInactivityThreshold,
  } = props

  const resetGuidedPutawayTime = async () => {
    await sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.UPDATE_CHROME_STORAGE, {
      storageType: 'local',
      items: {
        [CHROME_STORAGE_LOCAL_KEYS.LastActiveTime]: 0,
        [CHROME_STORAGE_LOCAL_KEYS.LastGuidedPutawayTime]: 0,
      },
    })
  }

  const restartOnboarding = async () => {
    window.localStorage.removeItem(WEBAPP_LOCAL_STORAGE_KEYS.CurrentOnboardingTutorialStepV2)
    window.localStorage.removeItem(WEBAPP_LOCAL_STORAGE_KEYS.CurrentOnboardingTutorialStepV3)
    await sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.UPDATE_CHROME_STORAGE, {
      storageType: 'local',
      items: {
        [CHROME_STORAGE_LOCAL_KEYS.IsOnboardingCompleted]: false,
      },
    })
    window.location.href = WEBAPP_BASE_URL
  }

  return (
    <Box>
      <Divider my="24px" />

      <Heading as="h2" mb="16px" fontSize="18px" fontWeight={600} color="#585858" lineHeight="22px">
        Internal Settings
      </Heading>

      <Box>
        <Heading as="h2" fontSize="14px" fontWeight={500} color="#585858" lineHeight="22px">
          Guided Auto Save Frequency
        </Heading>
        <Select
          value={guidedAutoSaveFrequencyThreshold}
          onChange={(e) => onChangeGuidedAutoSaveFrequencyThreshold(Number(e.target.value))}
          display="inline-block"
          w="150px"
          h="28px"
          mx={1}
          size="xs"
          name="guidedAutoSaveFrequencyThreshold"
          fontSize="12px"
          borderColor="black"
          borderRadius="8px"
          _hover={{ bg: '#E3E3E3' }}
        >
          {getOptionsForGuidedAutoSaveFrequencyThreshold()}
        </Select>
      </Box>

      <Box mt="16px">
        <Heading as="h2" fontSize="14px" fontWeight={500} color="#585858" lineHeight="22px">
          Guided Auto Save Inactivity Threshold
        </Heading>
        <Select
          value={guidedAutoSaveInactivityThreshold}
          onChange={(e) => onChangeGuidedAutoSaveInactivityThreshold(Number(e.target.value))}
          display="inline-block"
          w="150px"
          h="28px"
          mx={1}
          size="xs"
          name="guidedAutoSaveInactivityThreshold"
          fontSize="12px"
          borderColor="black"
          borderRadius="8px"
          _hover={{ bg: '#E3E3E3' }}
        >
          {getOptionsForGuidedAutoSaveInactivityThreshold()}
        </Select>
      </Box>

      <Button
        mt="24px"
        mx="8px"
        size="sm"
        onClick={resetGuidedPutawayTime}
        bg="black"
        color="white"
      >
        Reset Guided Putaway Time
      </Button>

      <Button mt="24px" mx="8px" size="sm" onClick={restartOnboarding} bg="black" color="white">
        Restart Onboarding
      </Button>
    </Box>
  )
}

export default InternalSettingsContent
