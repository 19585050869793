import React, { FC, useCallback } from 'react'
import OnboardingStepTemplate from '../OnboardingStepTemplate'
import { useGetAllTabRecordsQuery } from '../../../../webapp/redux/extension'
import { useAppendTablistPagesMutation } from '../../../../redux/services/skeema/tablist_pages.endpoints'
import { isSkeemaUrl, mapTabRecordToTablistPage } from '../../../../utils/tabUtils'
import { TabRecordType } from '../../../../extension/models/tabRecord.types'
import { TablistPageType } from '../../../../models/tablist_pages.types'

const getAllTabsToSave = (openTabRecords: TabRecordType[]): TablistPageType[] => {
  const windowIds = new Set(openTabRecords.map((t) => t.windowId))

  const allTabs = Array.from(windowIds)
    .map((id) => {
      return openTabRecords.filter(
        (t) =>
          t.windowId === id && !isSkeemaUrl({ url: t.url, shouldIncludeToleratedTabUrls: false }),
      )
    })
    .reduce((acc, val) => acc.concat(val), [])
    .map(mapTabRecordToTablistPage)

  return allTabs
}

interface Props {
  onNext: () => void
}

const OnboardingPrefaceV3Dialog: FC<Props> = (props) => {
  const { onNext } = props
  const [appendTablistPages] = useAppendTablistPagesMutation()
  const { data: openTabRecords, isLoading } = useGetAllTabRecordsQuery(undefined, {
    pollingInterval: 1000,
  })

  const handleNextStep = useCallback(async () => {
    const allTabsToSave = getAllTabsToSave(openTabRecords ?? [])
    const tabsToSave = allTabsToSave ?? []
    await appendTablistPages({ pages: tabsToSave, isManual: true })
      .unwrap()
      .then((res) => res)
      .catch(() => {
        console.error('ERROR: Saving tabs failed in V3 Stats')
      })

    onNext()
  }, [onNext, openTabRecords, appendTablistPages])

  return (
    <OnboardingStepTemplate
      phaseNum={2}
      numPhases={6}
      heading={
        <>
          {`Welcome to Skipper 👋`}
          <br />
          {`A clean browser and a calm mind is just 90 seconds away!`}
        </>
      }
      buttonText={`Let's go`}
      onButtonClick={handleNextStep}
      isButtonLoading={isLoading}
      delayButtonActivation={false}
    />
  )
}
export default OnboardingPrefaceV3Dialog
