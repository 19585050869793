import React, { FC } from 'react'
import { Text } from '@chakra-ui/react'
import OnboardingStepTemplate from '../OnboardingStepTemplate'

interface Props {
  onNext: () => void
}
const OnboardingBreatheEasyV3Dialog: FC<Props> = (props) => {
  const { onNext } = props

  return (
    <OnboardingStepTemplate
      phaseNum={2}
      numPhases={6}
      heading={
        <>
          {`Breathe easy, Skipper just safely stored all of these tabs for you.`}
          <Text mt="18px" fontWeight="400" fontSize="16px" color="#585858">
            <span style={{ textDecoration: 'underline' }}>All saved tabs</span> is the full list of
            tabs you&apos;ve spent time on, ordered by last time used.
          </Text>
        </>
      }
      buttonText={`Next`}
      onButtonClick={onNext}
      delayButtonActivation={true}
      delayLabel="Just a moment…"
    />
  )
}
export default OnboardingBreatheEasyV3Dialog
