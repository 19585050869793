import React, { FC } from 'react'
import GenericModal from '../../../widgets/GenericModal'
import { APP_NAME } from '../../../constants'

interface Props {
  isOpen: boolean
  onCancel: () => void
  onSubmit: () => void
}

const ProjectPageLimitModal: FC<Props> = (props) => {
  const { isOpen, onCancel, onSubmit } = props

  return (
    <GenericModal
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={onSubmit}
      headingText={`Tab collection growing? Love it! 📚`}
      bodyText={
        <>
          With {APP_NAME} Basic, you can store 10 tabs per folder.
          <br />
          <br />
          Ready for more space to capture all those ideas and research? Upgrade to Pro for up to 100
          tabs per folder.
        </>
      }
      cancelButtonText="Dismiss"
      submitButtonText="Upgrade"
    />
  )
}

export default ProjectPageLimitModal
