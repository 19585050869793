import React, { FC, useState } from 'react'
import OnboardingStepTemplate from './OnboardingStepTemplate'
import { useUserContext } from '../../../contexts/UserContext'
import { Box, Radio, Text } from '@chakra-ui/react'

const QUESTIONNAIRE_OPTIONS = [
  {
    label: 'Very disappointed',
    value: 'very_disappointed',
  },
  {
    label: 'Somewhat disappointed',
    value: 'somewhat_disappointed',
  },
  {
    label: 'Not disappointed',
    value: 'not_disappointed',
  },
]

interface Props {
  onNext: () => void
}
const OnboardingQuestionnaireDialog: FC<Props> = (props) => {
  const { onNext } = props
  const { captureAnalytics } = useUserContext()
  const [answer, setAnswer] = useState<string | null>(null)

  return (
    <OnboardingStepTemplate
      heading={
        <>
          {`How disappointed would you be if Skipper were no longer available?`}
          <Box mt="4">
            {QUESTIONNAIRE_OPTIONS.map((option) => (
              <Box key={option.value} mt="8px">
                <Radio
                  value={option.value}
                  isChecked={answer === option.value}
                  onChange={() => setAnswer(option.value)}
                  colorScheme="black"
                >
                  <Text fontSize="16px" fontWeight={400} lineHeight="22px" color="#585858">
                    {option.label}
                  </Text>
                </Radio>
              </Box>
            ))}
          </Box>
        </>
      }
      buttonText={`Submit`}
      buttonDisabled={!answer}
      onButtonClick={() => {
        captureAnalytics('onboarding:submit_survey_v2', {
          answer,
        })
        onNext()
      }}
      delayButtonActivation={false}
    />
  )
}
export default OnboardingQuestionnaireDialog
