export enum ONBOARDING_TUTORIAL_STEPS {
  Preface = 'Preface',
  PrefaceV3 = 'PrefaceV3',
  HowSkipperWorks = 'HowSkipperWorks',
  GettingStarted = 'GettingStarted',
  BreatheEasy = 'BreatheEasy',
  Sessions = 'Sessions',
  SessionsOrganize = 'SessionsOrganize',
  SessionsCleanUp = 'SessionsCleanUp',
  SessionsComplete = 'SessionsComplete',
  Intro = 'Intro',
  PinTabs = 'PinTabs',
  Stats = 'Stats',
  StatsV3 = 'StatsV3',
  GuidedAutoSave = 'GuidedAutoSave',
  GuidedAutoSaveV3 = 'GuidedAutoSaveV3',
  AutoSaveConfig = 'AutoSaveConfig',
  GuidedOrAutoSavePickerV3 = 'GuidedOrAutoSavePickerV3',
  TabList = 'TabList',
  NewFolderButton = 'NewFolderButton',
  FolderCreation = 'FolderCreation',
  FolderCreated = 'FolderCreated',
  DraftProjects = 'DraftProjects',
  ConclusionV3 = 'ConclusionV3',
  Conclusion = 'Conclusion',
  Questionnaire = 'Questionnaire',
  DONE = 'DONE_V2',
}

export const VALID_ONBOARDING_TUTORIAL_STEPS: {
  //The boolean value is redundant, but we use an object here
  //so that typescript will ensure every enum value is present
  [key in ONBOARDING_TUTORIAL_STEPS]: boolean
} = {
  [ONBOARDING_TUTORIAL_STEPS.Preface]: true,
  [ONBOARDING_TUTORIAL_STEPS.PrefaceV3]: true,
  [ONBOARDING_TUTORIAL_STEPS.HowSkipperWorks]: true,
  [ONBOARDING_TUTORIAL_STEPS.GettingStarted]: true,
  [ONBOARDING_TUTORIAL_STEPS.BreatheEasy]: true,
  [ONBOARDING_TUTORIAL_STEPS.StatsV3]: true,
  [ONBOARDING_TUTORIAL_STEPS.Stats]: true,
  [ONBOARDING_TUTORIAL_STEPS.Sessions]: true,
  [ONBOARDING_TUTORIAL_STEPS.SessionsOrganize]: true,
  [ONBOARDING_TUTORIAL_STEPS.SessionsCleanUp]: true,
  [ONBOARDING_TUTORIAL_STEPS.SessionsComplete]: true,
  [ONBOARDING_TUTORIAL_STEPS.Intro]: true,
  [ONBOARDING_TUTORIAL_STEPS.PinTabs]: true,
  [ONBOARDING_TUTORIAL_STEPS.GuidedAutoSave]: true,
  [ONBOARDING_TUTORIAL_STEPS.GuidedAutoSaveV3]: true,
  [ONBOARDING_TUTORIAL_STEPS.GuidedOrAutoSavePickerV3]: true,
  [ONBOARDING_TUTORIAL_STEPS.TabList]: true,
  [ONBOARDING_TUTORIAL_STEPS.AutoSaveConfig]: true,
  [ONBOARDING_TUTORIAL_STEPS.NewFolderButton]: true,
  [ONBOARDING_TUTORIAL_STEPS.FolderCreation]: true,
  [ONBOARDING_TUTORIAL_STEPS.FolderCreated]: true,
  [ONBOARDING_TUTORIAL_STEPS.DraftProjects]: true,
  [ONBOARDING_TUTORIAL_STEPS.Conclusion]: true,
  [ONBOARDING_TUTORIAL_STEPS.ConclusionV3]: true,
  [ONBOARDING_TUTORIAL_STEPS.DONE]: true,
  [ONBOARDING_TUTORIAL_STEPS.Questionnaire]: true,
}
