import React, { FC } from 'react'
import OnboardingStepTemplate from './OnboardingStepTemplate'
import { WEBAPP_LOCAL_STORAGE_KEYS } from '../../../models/browserStorage.types'
interface Props {
  phaseNum: number
  numPhases: number
}
const OnboardingNewFolderButtonDialog: FC<Props> = (props) => {
  const { phaseNum, numPhases } = props

  const headingWithTabGroups = (
    <span
      style={{
        fontSize: 'inherit',
        fontWeight: 'inherit',
        lineHeight: 'inherit',
        color: 'inherit',
        letterSpacing: 'inherit',
      }}
    >
      The second thing Skipper helps with is organizing related tabs together into folders.
      <br />
      <br />
      Think bookmarks, but smarter. We already saved your tab groups as folders. Click the{' '}
      <span
        style={{
          whiteSpace: 'nowrap',
          fontSize: 'inherit',
          fontWeight: 'inherit',
          lineHeight: 'inherit',
          color: 'inherit',
          letterSpacing: 'inherit',
        }}
      >
        [+ New Folder]
      </span>{' '}
      button to create another one now.
    </span>
  )

  const headingWithoutTabGroups = (
    <span
      style={{
        fontSize: 'inherit',
        fontWeight: 'inherit',
        lineHeight: 'inherit',
        color: 'inherit',
        letterSpacing: 'inherit',
      }}
    >
      The second thing Skipper helps with is organizing related tabs together into folders.
      <br />
      <br />
      Think bookmarks, but smarter. Click the{' '}
      <span
        style={{
          whiteSpace: 'nowrap',
          fontSize: 'inherit',
          fontWeight: 'inherit',
          lineHeight: 'inherit',
          color: 'inherit',
          letterSpacing: 'inherit',
        }}
      >
        [+ New Folder]
      </span>{' '}
      button to set up your first one now.
    </span>
  )

  return (
    <OnboardingStepTemplate
      phaseNum={phaseNum}
      phaseText={`2. ORGANIZING`}
      numPhases={numPhases}
      heading={
        window.localStorage.getItem(WEBAPP_LOCAL_STORAGE_KEYS.DidCreateProjectsFromTabgroups) ===
        'true'
          ? headingWithTabGroups
          : headingWithoutTabGroups
      }
    />
  )
}
export default OnboardingNewFolderButtonDialog
