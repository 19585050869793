import React, { FC, useEffect, useState } from 'react'
import OnboardingStepTemplate from '../OnboardingStepTemplate'
import { isMac } from '../../../../utils/utils'
import { Text } from '@chakra-ui/react'
import {
  EXTENSION_SETTINGS_KEYS,
  ExtensionSettingsType,
} from '../../../../extension/models/extensionStorage.types'
import { sendMessageToExtension } from '../../../../webapp/utils/externalMessaging'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../../extension/models/messaging.types'
import { styled } from 'styled-components'
import { Flex } from '@chakra-ui/react'
import {
  SKIPPER_TAB_LOCATION_WINDOWS_IMG_URL,
  SKIPPER_TAB_LOCATION_MAC_IMG_URL,
} from '../../../../constants'

const cmdOrCtrl = isMac() ? 'Cmd' : 'Ctrl'
const SKIPPER_TAB_LOCATION_IMG_URL = isMac()
  ? SKIPPER_TAB_LOCATION_MAC_IMG_URL
  : SKIPPER_TAB_LOCATION_WINDOWS_IMG_URL

const KeyIcon = styled.span`
  position: relative;
  top: -2px;
  font-size: 18px;
  font-weight: 600;
  background-color: rgb(78, 78, 78);
  color: white;
  border-radius: 4px;
  margin-right: 2px;
  padding: 4px 6px;
`

interface Props {
  onNext: () => void
}
const OnboardingConclusionV3Dialog: FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const { onNext } = props

  const [extensionSettings, setExtensionSettings] = useState<
    Pick<ExtensionSettingsType, EXTENSION_SETTINGS_KEYS.IS_GUIDED_AUTO_SAVE_ENABLED> | undefined
  >(undefined)

  const { isGuidedAutoSaveEnabled } = extensionSettings || {}

  useEffect(() => {
    setIsLoading(true)
    sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.GET_EXTENSION_SETTINGS)
      .then((response) => {
        const responseSettings = response as ExtensionSettingsType
        setExtensionSettings({
          [EXTENSION_SETTINGS_KEYS.IS_GUIDED_AUTO_SAVE_ENABLED]:
            responseSettings[EXTENSION_SETTINGS_KEYS.IS_GUIDED_AUTO_SAVE_ENABLED],
        })
        setIsLoading(false)
      })
      .catch((e) => {
        console.error('Error fetching extensionSettings', e)
      })
  }, [])

  return (
    <OnboardingStepTemplate
      phaseNum={10}
      numPhases={10}
      isCardLoading={isLoading}
      heading={
        <>
          {!isGuidedAutoSaveEnabled
            ? `Sounds good! Skipper will automatically close any tabs you haven't used in over a day.`
            : `Sounds good! Once a day, Skipper will remind you about which tabs you haven't been looking at and should close.`}
          <Text mt="4" color="#585858" fontWeight={400}>
            {!isGuidedAutoSaveEnabled ? (
              <>
                You can always find them right here in Skipper, accessible via shortcut ({' '}
                <KeyIcon>{cmdOrCtrl}</KeyIcon>
                <KeyIcon>1</KeyIcon> )
              </>
            ) : (
              `You can always go to Settings to switch to automatic saving!`
            )}
          </Text>
        </>
      }
      bodyContent={
        !isGuidedAutoSaveEnabled ? (
          <Flex minHeight="175px" justifyContent="center" alignItems="center">
            <img alt="Instructional image for Skipper" src={SKIPPER_TAB_LOCATION_IMG_URL} />
          </Flex>
        ) : null
      }
      buttonText={`Let's get to it! 🚀`}
      onButtonClick={onNext}
      delayButtonActivation={false}
    />
  )
}
export default OnboardingConclusionV3Dialog
