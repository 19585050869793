import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import OnboardingStepTemplate from '../OnboardingStepTemplate'
import { useGetOpenTabsQuery } from '../../../../webapp/redux/extension'
import { Box, Radio, Text } from '@chakra-ui/react'
import { sendMessageToExtension } from '../../../../webapp/utils/externalMessaging'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../../extension/models/messaging.types'
import {
  EXTENSION_SETTINGS_KEYS,
  ExtensionSettingsType,
} from '../../../../extension/models/extensionStorage.types'
import { useUserContext } from '../../../../contexts/UserContext'

interface Props {
  onNext: () => void
}
const OnboardingGuidedOrAutoSavePickerDialogV3: FC<Props> = (props) => {
  const { onNext } = props
  const { data: tabs, isLoading } = useGetOpenTabsQuery(undefined, { pollingInterval: 1000 })
  const { captureAnalytics } = useUserContext()
  const [settingsLoading, setSettingsLoading] = useState<boolean>(true)

  const [extensionSettings, setExtensionSettings] = useState<
    Pick<ExtensionSettingsType, EXTENSION_SETTINGS_KEYS.IS_GUIDED_AUTO_SAVE_ENABLED> | undefined
  >(undefined)

  const { isGuidedAutoSaveEnabled } = extensionSettings || {}

  const [numTabs] = useMemo(() => {
    if (!tabs) {
      return [undefined, undefined, undefined, undefined]
    }

    return [tabs.length]
  }, [tabs])

  const handleGuidedAutomaticSaveChange = useCallback(
    async (isGuided: boolean, noCapture: boolean = false) => {
      await sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.SAVE_EXTENSION_SETTINGS, {
        settings: {
          [EXTENSION_SETTINGS_KEYS.IS_GUIDED_AUTO_SAVE_ENABLED]: isGuided,
        },
      })
      setExtensionSettings((prev) =>
        prev
          ? {
              ...prev,
              [EXTENSION_SETTINGS_KEYS.IS_GUIDED_AUTO_SAVE_ENABLED]: isGuided,
            }
          : prev,
      )
      if (!noCapture) {
        captureAnalytics('onboarding:guided_automatic_save_change', {
          isGuidedAutoSaveEnabled: isGuided,
        })
      }
    },
    [captureAnalytics],
  )

  const fetchExtensionSettings = useCallback(async () => {
    try {
      setSettingsLoading(true)
      await handleGuidedAutomaticSaveChange(false, true)
      const response = await sendMessageToExtension(
        BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.GET_EXTENSION_SETTINGS,
      )
      const responseSettings = response as ExtensionSettingsType
      setExtensionSettings({
        [EXTENSION_SETTINGS_KEYS.IS_GUIDED_AUTO_SAVE_ENABLED]:
          responseSettings[EXTENSION_SETTINGS_KEYS.IS_GUIDED_AUTO_SAVE_ENABLED],
      })
    } catch (e) {
      console.error('Error fetching extensionSettings', e)
    } finally {
      setSettingsLoading(false)
    }
  }, [handleGuidedAutomaticSaveChange])

  useEffect(() => {
    fetchExtensionSettings()
  }, [fetchExtensionSettings])

  return (
    <OnboardingStepTemplate
      phaseNum={9}
      numPhases={10}
      isCardLoading={settingsLoading}
      heading={
        <>
          {`Done! Down to ${isLoading ? '...' : numTabs} ${numTabs === 1 ? 'tab' : 'tabs'}. 🎉`}
          <br />
          <br />
          {'Want Skipper to do that same tab saving magic automatically from now on?'}

          <Box mt="6">
            <Box>
              <Radio
                key="automatic-save"
                value="automatic-save"
                isChecked={!isGuidedAutoSaveEnabled}
                onChange={() => handleGuidedAutomaticSaveChange(false)}
                colorScheme="black"
              >
                <Text fontSize="16px" fontWeight={500} lineHeight="22px">
                  Keep working your magic! 🪄 [Recommended]
                </Text>
              </Radio>
              <Text ml="24px" fontWeight={400} fontSize="16px" lineHeight="22px" color="#585858">
                We&apos;ll close tabs you haven&apos;t looked at in more than a day (you can always
                find them in Skipper)
              </Text>
            </Box>
            <Box mt="32px">
              <Radio
                key="guided-automatic-save"
                value="guided-automatic-save"
                isChecked={isGuidedAutoSaveEnabled}
                onChange={() => handleGuidedAutomaticSaveChange(true)}
                colorScheme="black"
              >
                <Text fontSize="16px" fontWeight={500} lineHeight="22px">
                  Let me review before closing 🔎
                </Text>
              </Radio>
              <Text ml="24px" fontWeight={400} fontSize="16px" lineHeight="22px" color="#585858">
                Every day we&apos;ll remind you which tabs you haven&apos;t been looking at so you
                can close them yourself
              </Text>
            </Box>
          </Box>
        </>
      }
      buttonText={`I feel good! 💪`}
      onButtonClick={onNext}
      delayButtonActivation={false}
    />
  )
}
export default OnboardingGuidedOrAutoSavePickerDialogV3
