import React, { FC, useCallback, useEffect, useMemo } from 'react'
import OnboardingStepTemplate from '../OnboardingStepTemplate'
import { Box, Button, Text } from '@chakra-ui/react'
import { styled } from 'styled-components'
import { useReduxSelector } from '../../../../redux/baseStore'
import { selectSessionSessions } from '../../../../redux/sessionSlice'
import { useDeleteOpenTabsMutation } from '../../../../webapp/redux/extension'
import {
  ONBOARDING_ORGANIZED_SESSION_ID_KEY,
  ONBOARDING_SKIPPED_CLEANUP_KEY,
} from '../../../../utils/sessionUtils'
import { useUserContext } from '../../../../contexts/UserContext'

const Container = styled(Box)`
  .large-text,
  .large-text * {
    font-size: 28px;
    font-weight: 500;
    line-height: 40px;
    color: #000;
    letter-spacing: -1px;

    @media (max-width: 1200px) {
      /* Screens narrower than 1200px */
      font-size: 24px;
      line-height: 36px;
    }
  }
`

interface Props {
  onNext: () => void
}
const OnboardingSessionsCleanUpV3Dialog: FC<Props> = (props) => {
  const { onNext } = props
  const sessions = useReduxSelector(selectSessionSessions)
  const [deleteOpenTabs] = useDeleteOpenTabsMutation()
  const { captureAnalytics } = useUserContext()

  // Filter out the session used in previous step
  const filteredSessions = useMemo(() => {
    const organizedSessionId = localStorage.getItem(ONBOARDING_ORGANIZED_SESSION_ID_KEY)
    const uniqueSessions = sessions?.filter(
      (session) => session.id.toString() !== organizedSessionId,
    )
    const sessionsWithOpenTabs = uniqueSessions?.filter((session) =>
      session.pages.some((page) => page.is_open),
    )
    const tabsOlderThanAnHour = sessionsWithOpenTabs?.filter((session) =>
      session.pages.some((page) => {
        const oneHourAgo = Date.now() - 60 * 60 * 1000
        return page.last_access_timestamp_ms < oneHourAgo
      }),
    )
    return tabsOlderThanAnHour
  }, [sessions])

  const oldestSession = useMemo(() => {
    if (!filteredSessions || filteredSessions.length === 0) {
      return undefined
    }

    // Sort sessions by their start timestamp in ascending order to get the oldest session
    const sortedSessions = [...(filteredSessions ?? [])].sort(
      (a, b) => a.datetime_start_ts - b.datetime_start_ts,
    )

    // Find the session with at least one open page that has the oldest last_access_timestamp_ms
    const sessionsWithOpenPages = sortedSessions.sort((a, b) => {
      const oldestPageA = Math.min(
        ...a.pages.filter((page) => page.is_open).map((page) => page.last_access_timestamp_ms),
      )
      const oldestPageB = Math.min(
        ...b.pages.filter((page) => page.is_open).map((page) => page.last_access_timestamp_ms),
      )
      return oldestPageA - oldestPageB
    })

    // If no sessions with open pages that haven't been viewed recently are found, fall back to the most recent session
    return sessionsWithOpenPages.length > 0 ? sessionsWithOpenPages[0] : sortedSessions[0]
  }, [filteredSessions])

  const closeSessionTabs = useCallback(() => {
    const ids =
      oldestSession?.pages
        .filter((page) => page?.is_open)
        .map((page) => page?.id?.toString())
        .filter((id): id is string => id !== undefined) ?? []
    if (ids.length > 0) {
      deleteOpenTabs({ ids })
    }
  }, [oldestSession, deleteOpenTabs])

  const goNext = useCallback(() => {
    localStorage.removeItem(ONBOARDING_ORGANIZED_SESSION_ID_KEY)
    onNext()
  }, [onNext])

  useEffect(() => {
    if (oldestSession) {
      setTimeout(() => {
        const element = document.querySelector(`[data-session-id="${oldestSession?.id}"]`)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      }, 500)
    } else {
      captureAnalytics('onboarding_tutorial:skipped_session_cleanup')
      localStorage.setItem(ONBOARDING_SKIPPED_CLEANUP_KEY, 'true')
      goNext()
    }
  }, [oldestSession, goNext, captureAnalytics])

  return (
    <OnboardingStepTemplate
      phaseNum={4}
      numPhases={6}
      bodyContent={
        <Container>
          <Text className="large-text" mb="4px">
            We noticed you haven&apos;t looked at your &quot;{oldestSession?.name}&quot; tabs in a
            while.
          </Text>
          <Text mt="4" className="large-text" mb="4px">
            Why don&apos;t we close those for you, since they&apos;re safe and sound in your
            Session?
          </Text>
        </Container>
      }
      buttonText={`Yes close them`}
      onButtonClick={() => {
        closeSessionTabs()
        goNext()
      }}
      delayButtonActivation={false}
      secondaryButton={
        <>
          <Button
            marginRight="8px"
            size="lg"
            variant="ghost"
            borderRadius="100px"
            paddingRight="24px"
            paddingLeft="24px"
            color={'black'}
            border="1px solid #FFF"
            fontWeight={500}
            _hover={{
              backgroundColor: '#EBEBEB',
            }}
            onClick={goNext}
            isLoading={false}
            isDisabled={false}
          >
            Keep them open
          </Button>
        </>
      }
    />
  )
}

export default OnboardingSessionsCleanUpV3Dialog
