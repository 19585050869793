import React, { FC } from 'react'
import { Tooltip, Text, IconButton, Heading, Box, Flex, Icon, Center } from '@chakra-ui/react'
import {
  MdArrowOutward,
  MdCheck,
  MdClose,
  MdOpenInNew,
  MdOutlineCreateNewFolder,
} from 'react-icons/md'
import DragIcon from '../../../components/DragIcon'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import { useUserContext } from '../../../contexts/UserContext'
import { useReduxSelector } from '../../../redux/baseStore'
import { selectPendingFolderCreationPages } from '../../../redux/projectsSlice'
import { TablistPageType } from '../../../models/tablist_pages.types'

interface Props {
  title: string
  color: string
  handleClickOpenIcon: (event: React.MouseEvent<Element, MouseEvent>) => void
  handleClickOpenInNewWindowIcon: (event: React.MouseEvent<Element, MouseEvent>) => void
  handleClickCreateProjectIcon: (event: React.MouseEvent<Element, MouseEvent>) => void
  handleClickDeleteIcon?: (event: React.MouseEvent<Element, MouseEvent>) => void
  handleClickFolderCreationAddAllButton: (event: React.MouseEvent<Element, MouseEvent>) => void
  shouldHideActionIcons: boolean
  isFolderCreationModeActive?: boolean
  showAiSuggestion?: boolean
  aiSuggestionReason?: string
  pages: TablistPageType[]
}

const SavedTabsTopicGroupCardTitle: FC<Props> = (props) => {
  const {
    title,
    color,
    pages,
    handleClickOpenIcon,
    handleClickOpenInNewWindowIcon,
    handleClickCreateProjectIcon,
    handleClickDeleteIcon,
    handleClickFolderCreationAddAllButton,
    shouldHideActionIcons,
    isFolderCreationModeActive = false,
    showAiSuggestion,
    aiSuggestionReason,
  } = props
  const createProjectButtonTooltip = `Save as folder`
  const { userInfo } = useUserContext()
  const pendingPages = useReduxSelector(selectPendingFolderCreationPages)
  const numPendingPages = pendingPages.length
  const isPremium = !!userInfo?.is_premium
  const { projectConfig } = useFeatureFlagContext()
  const maxNumPages = projectConfig.maxNumProjectsPages
  const isProjectPageLimitReached =
    numPendingPages >= maxNumPages || numPendingPages + pages.length > maxNumPages

  return (
    <Flex
      position="relative"
      width="100%"
      h="22px"
      justifyContent="space-between"
      alignItems="center"
      overflow="hidden"
      marginBottom="6px"
    >
      <Flex alignItems="center" h="100%">
        <Flex
          as="span"
          className="hover-show-title-icons"
          alignItems="center"
          justifyContent="center"
          flexShrink={0}
          overflow="hidden"
          cursor="grab"
          width="16px"
          transition="opacity 0.2s"
          marginRight="-0.5px"
          opacity={0}
        >
          <DragIcon />
        </Flex>
        <Flex alignItems="center" h="100%" cursor="grab">
          <Center h="100%" m="0px 6px 0px 0px">
            <Box w="8px" h="8px" m="0px 4px" backgroundColor={color} borderRadius="4px" />
          </Center>

          <Heading
            as="h2"
            color="#585858"
            transition="color 0.2s"
            fontSize="12px"
            fontWeight="500"
            wordBreak="break-word"
            lineHeight="16px"
            cursor="grab"
          >
            {title}
          </Heading>
        </Flex>

        {showAiSuggestion && (
          <Box
            bg="rgba(12, 137, 78, 0.2)"
            color="#0C894E"
            borderRadius="16px"
            h="16px"
            minW="275px"
            p="2px 6px"
            mr="8px"
            display="flex"
            alignItems="center"
          >
            <Text fontSize="12px" fontWeight={500} mr="4px">
              {aiSuggestionReason}
            </Text>
            <Icon as={MdClose} color="#0C894E" />
            <Icon as={MdCheck} color="#0C894E" />
          </Box>
        )}
      </Flex>

      <Flex
        height="22px"
        flex="0 0 auto"
        alignItems="center"
        marginLeft="8px"
        marginRight={isFolderCreationModeActive ? '3px' : '6px'}
        opacity={shouldHideActionIcons ? 0 : 1}
        pointerEvents={shouldHideActionIcons ? 'none' : 'auto'}
        transition="opacity 0.2s"
      >
        {isFolderCreationModeActive && (
          <Tooltip
            placement="top"
            label={
              isProjectPageLimitReached ? (
                <>
                  Tab limit reached!
                  <br />
                  {isPremium ? 'Pro' : 'Basic'} folders hold {maxNumPages} tabs (need more space?)
                  ✨
                </>
              ) : (
                'Add all tabs to folder'
              )
            }
          >
            <Flex
              bg="#CCE3F9"
              borderRadius="6px"
              color="#0071E3"
              w="67px"
              h="22px"
              flexShrink={0}
              alignItems="center"
              justifyContent="center"
              onClick={
                isProjectPageLimitReached ? undefined : handleClickFolderCreationAddAllButton
              }
              cursor={isProjectPageLimitReached ? 'not-allowed' : 'pointer'}
              opacity={isProjectPageLimitReached ? 0.4 : 1}
            >
              <Text fontSize="12px" fontWeight="500">
                + Add all
              </Text>
            </Flex>
          </Tooltip>
        )}
        {!isFolderCreationModeActive && (
          <Flex
            className="hover-show-title-icons"
            alignItems="center"
            transition="opacity 0.2s"
            opacity={0}
            cursor="pointer"
          >
            <Tooltip label={`Open in new window`} placement="top">
              <Flex alignItems="center" justifyContent="center" height="100%">
                <IconButton
                  variant="outline"
                  border="none"
                  aria-label={`Open in new window`}
                  as="a"
                  icon={<MdOpenInNew color="#585858" />}
                  onClick={handleClickOpenInNewWindowIcon}
                  height="24px"
                  width="24px"
                  minWidth="24px"
                  tabIndex={-1}
                  _hover={{ background: 'inherit' }}
                />
              </Flex>
            </Tooltip>
            <Tooltip label={`Open`} placement="top">
              <Flex alignItems="center" justifyContent="center" height="100%">
                <IconButton
                  variant="outline"
                  border="none"
                  aria-label={`Open`}
                  as="a"
                  icon={<MdArrowOutward color="#585858" />}
                  onClick={handleClickOpenIcon}
                  height="24px"
                  width="24px"
                  minWidth="24px"
                  tabIndex={-1}
                  _hover={{ background: 'inherit' }}
                />
              </Flex>
            </Tooltip>
            <Tooltip label={createProjectButtonTooltip} placement="top">
              <Flex alignItems="center" justifyContent="center" height="100%">
                <IconButton
                  variant="outline"
                  border="none"
                  aria-label={createProjectButtonTooltip}
                  as="a"
                  icon={<MdOutlineCreateNewFolder color="#585858" />}
                  onClick={handleClickCreateProjectIcon}
                  pointerEvents={shouldHideActionIcons ? 'none' : 'auto'}
                  height="24px"
                  width="24px"
                  minWidth="24px"
                  tabIndex={-1}
                  _hover={{ background: 'inherit' }}
                />
              </Flex>
            </Tooltip>
            {!!handleClickDeleteIcon && (
              <Tooltip label={'Remove tabs'} placement="top">
                <Flex alignItems="center" justifyContent="center" height="100%">
                  <IconButton
                    variant="outline"
                    border="none"
                    aria-label={'Remove tabs'}
                    as="a"
                    icon={<MdClose color="#585858" />}
                    onClick={handleClickDeleteIcon}
                    height="24px"
                    width="24px"
                    minWidth="24px"
                    tabIndex={-1}
                    _hover={{ background: 'inherit' }}
                  />
                </Flex>
              </Tooltip>
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default SavedTabsTopicGroupCardTitle
