import React, { FC, useEffect } from 'react'
import { styled } from 'styled-components'
import { Modal, ModalContent, ModalOverlay, Flex, Text } from '@chakra-ui/react'
import { useLocalStorageBoolean } from '../../../utils/genericHooks'
import { WEBAPP_LOCAL_STORAGE_KEYS } from '../../../models/browserStorage.types'
import { useUserContext } from '../../../contexts/UserContext'
import OnboardingStepTemplate from './OnboardingStepTemplate'
import { PIN_SKIPPER_GIF } from '../../../constants'

const CopyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  & > p {
    font-size: 16px;
    color: #585858;
    white-space: pre;
  }
`

const PinningTutorialOverlay: FC = () => {
  const { captureAnalytics, userInfo } = useUserContext()

  const [isReadyForPinningTutorial] = useLocalStorageBoolean(
    WEBAPP_LOCAL_STORAGE_KEYS.IsReadyForPinningTutorial,
    null,
  )
  const [isPinningTutorialVisible, setIsPinningTutorialVisible] = useLocalStorageBoolean(
    WEBAPP_LOCAL_STORAGE_KEYS.IsPinningTutorialVisible,
    null,
  )
  const isOpen = !!isPinningTutorialVisible

  useEffect(() => {
    if (
      userInfo &&
      userInfo.date_joined &&
      isPinningTutorialVisible === null &&
      isReadyForPinningTutorial
    ) {
      const dateJoined = new Date(userInfo.date_joined)
      const currentDate = new Date()
      const timeDifference = Math.abs(currentDate.getTime() - dateJoined.getTime())
      const differenceInDays = Math.floor(timeDifference / (1000 * 3600 * 24))

      if (differenceInDays > 1) {
        captureAnalytics('pinning_tutorial_overlay:tutorial_view')
        setIsPinningTutorialVisible(true)
        window.localStorage.removeItem(WEBAPP_LOCAL_STORAGE_KEYS.IsReadyForPinningTutorial)
      }
    }
  }, [
    captureAnalytics,
    isPinningTutorialVisible,
    isReadyForPinningTutorial,
    setIsPinningTutorialVisible,
    userInfo,
  ])

  const onClose = () => {
    captureAnalytics('pinning_tutorial_overlay:close_button_click')
    setIsPinningTutorialVisible(false)
  }

  const onSubmit = () => {
    captureAnalytics('pinning_tutorial_overlay:submit_button_click')
    setIsPinningTutorialVisible(false)
  }

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="transparent" top="16px" right="48px" position={'absolute'} margin="0">
        <OnboardingStepTemplate
          maxWidth="480px"
          heading={`Pin Skipper so it's always handy!`}
          bodyContent={
            <>
              <CopyContainer>
                <Text mt="2" color="#585858" fontWeight={400}>
                  Click the puzzle piece icon above and pin Skipper
                </Text>
              </CopyContainer>

              <Flex
                m="24px 0"
                height="250px"
                minHeight="175px"
                justifyContent="center"
                alignItems="center"
              >
                <img
                  style={{
                    height: '100%',
                    width: '100%',
                    borderRadius: '16px',
                    border: '1px solid #e4e7ec',
                    marginBottom: '16px',
                  }}
                  alt="Instructional image for Instant Save feature"
                  src={PIN_SKIPPER_GIF}
                />
              </Flex>

              <CopyContainer>
                <Text color="#585858" fontWeight={400}>
                  Use the pop up to save any tab into Skipper instantly!
                </Text>
              </CopyContainer>
            </>
          }
          buttonText={`I've pinned it`}
          onButtonClick={onSubmit}
        />
      </ModalContent>
    </Modal>
  )
}

export default PinningTutorialOverlay
