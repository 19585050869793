import React, { FC } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'

interface Props {
  isOpen: boolean
  onCancel: () => void
  onSubmit: () => void
  headingText: string
  bodyText: string | React.ReactNode
  cancelButtonText: string
  submitButtonText: string
  shouldShowCloseIcon?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg'
}

const GenericModal: FC<Props> = (props) => {
  const {
    isOpen,
    onCancel,
    onSubmit,
    headingText,
    bodyText,
    cancelButtonText,
    submitButtonText,
    shouldShowCloseIcon = false,
    size = 'md',
  } = props

  const sidePadding = size === 'xs' ? '20px' : '24px'

  const modalHeaderFontSize = size === 'xs' ? '16px' : '20px'
  const modalHeaderBottomPadding = size === 'xs' ? '4px' : '16px'
  const modalBodyFontSize = size === 'xs' ? '14px' : '16px'
  const modalBodyTopPadding = size === 'xs' ? '4px' : '8px'
  const buttonFontSize = size === 'xs' ? '14px' : '16px'

  return (
    <Modal isCentered isOpen={isOpen} onClose={onCancel} size={size}>
      <ModalOverlay backdropFilter="auto" backdropBlur={'4px'} />
      <ModalContent borderRadius={'16px'}>
        <ModalHeader
          fontSize={modalHeaderFontSize}
          px={sidePadding}
          pt={sidePadding}
          pb={modalHeaderBottomPadding}
        >
          {headingText}
        </ModalHeader>
        {shouldShowCloseIcon && <ModalCloseButton />}
        <ModalBody pt={modalBodyTopPadding} px={sidePadding}>
          <Text fontSize={modalBodyFontSize} color="black">
            {bodyText}
          </Text>
        </ModalBody>

        <ModalFooter px={sidePadding} pb={sidePadding}>
          <Button borderRadius={'100px'} variant="ghost" mr={3} onClick={onCancel}>
            {cancelButtonText}
          </Button>

          <Button
            size="md"
            variant="solid"
            borderRadius="100px"
            paddingRight="24px"
            paddingLeft="24px"
            backgroundColor={'black'}
            color={'white'}
            fontSize={buttonFontSize}
            _hover={{
              backgroundColor: '#585858',
            }}
            onClick={onSubmit}
            autoFocus
          >
            {submitButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default GenericModal
