import React, { FC, useCallback, useMemo, useRef, useState } from 'react'
import { UseToastOptions, ToastId, useToast } from '@chakra-ui/react'
import { MdOutlineFolder } from 'react-icons/md'
import {
  useMoveProjectPageMutation,
  useGetActiveProjectsQuery,
} from '../../../redux/services/skeema/projects.endpoints'
import { ProjectType } from '../../../models/saved_sessions.types'
import { useUserContext } from '../../../contexts/UserContext'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import NotificationToast from '../../../components/NotificationToast'
import MoveItemMenuView from './MoveItemMenuView'
import { MoveProjectPageInfoType } from './moveItemMenu.types'
import { useProjectLimitModalContext } from '../../../contexts/ProjectLimitModalContext'

const USE_TOAST_OPTIONS: UseToastOptions = {
  position: 'bottom',
  containerStyle: {
    maxWidth: 'none',
    margin: 0,
  },
}

interface Props {
  pageInfo?: MoveProjectPageInfoType
  onSuccess: () => void
}

const MoveProjectItemMenu: FC<Props> = (props) => {
  const { pageInfo, onSuccess } = props
  const { captureAnalytics } = useUserContext()
  const { projectConfig } = useFeatureFlagContext()
  const maxNumProjectPages = projectConfig.maxNumProjectsPages
  const { openProjectLimitModal } = useProjectLimitModalContext()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const toast = useToast(USE_TOAST_OPTIONS)
  const toastIdRef = useRef<ToastId | undefined>(undefined)

  const { data: projects, isLoading: isProjectsQueryLoading } = useGetActiveProjectsQuery(undefined)
  const filteredProjects = useMemo(
    () => projects?.filter((project) => project.id !== pageInfo?.project.id),
    [projects, pageInfo],
  )
  const [moveProjectPageMutation] = useMoveProjectPageMutation()

  const onClickMoveToProject = useCallback(
    async (params: { project: ProjectType }) => {
      const { project } = params
      if (!pageInfo) {
        return
      }

      if (project.pages.length >= maxNumProjectPages) {
        captureAnalytics('move_item_menu:move_to_project_click_paywall_hit')
        onSuccess()
        openProjectLimitModal()
        return
      }

      setIsLoading(true)

      await moveProjectPageMutation({
        projectId: pageInfo.project.id,
        page: pageInfo.page,
        index: 0,
        destProject: project,
        maxNumProjectPages,
      })
        .then((result) => {
          if ('error' in result) {
            console.error(result.error)
            return undefined
          }
          return result.data
        })
        .catch((e) => {
          console.error(e)
          return undefined
        })

      setIsLoading(false)

      if (toastIdRef.current) {
        toast.close(toastIdRef.current)
      }
      toastIdRef.current = toast({
        duration: 3000,
        containerStyle: { minWidth: 'auto' },
        render: () => <NotificationToast message={`Tab has been moved to "${project.title}"`} />,
      })

      captureAnalytics('move_item_menu:move_to_project_click', {
        newProjectId: project.id,
        newProjectTitle: project.title,
        oldProjectId: pageInfo.project.id,
        oldProjectTitle: pageInfo.project.title,
        page: pageInfo.page,
      })

      onSuccess()
    },
    [
      pageInfo,
      moveProjectPageMutation,
      maxNumProjectPages,
      toast,
      captureAnalytics,
      onSuccess,
      openProjectLimitModal,
    ],
  )

  return (
    <MoveItemMenuView
      actionText={'Move'}
      actionSubtext={'Move to...'}
      itemTitle={pageInfo?.page.title ?? ''}
      locationIcon={MdOutlineFolder}
      locationText={pageInfo?.project.title ?? ''}
      areProjectsLoading={isProjectsQueryLoading}
      projects={filteredProjects}
      onClickMoveToProject={onClickMoveToProject}
      isLoading={isLoading}
    />
  )
}

export default MoveProjectItemMenu
