import React, { FC } from 'react'
import OnboardingStepTemplate from '../OnboardingStepTemplate'
import { styled } from 'styled-components'

const Text = styled.div`
  font-weight: 500;
  font-size: 24px;
`

interface Props {
  onNext: () => void
}
const OnboardingHowSkipperWorksV3Dialog: FC<Props> = (props) => {
  const { onNext } = props

  return (
    <OnboardingStepTemplate
      phaseNum={2}
      numPhases={6}
      heading={
        <>
          {`Skipper works with your brain by:`}
          <br />
          <Text
            style={{
              marginTop: 32,
            }}
          >
            1. Automatically saving tabs you&apos;re not using 💾
          </Text>
          <Text>2. Organizing them into clear groups for later 🗂️</Text>
          <Text>3. Making it easy to switch between things 🔄</Text>
        </>
      }
      buttonText={`Next`}
      onButtonClick={onNext}
      delayButtonActivation={false}
    />
  )
}
export default OnboardingHowSkipperWorksV3Dialog
