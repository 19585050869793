import React, { createContext, FC, useContext, useState, useCallback } from 'react'

interface ProjectLimitModalContextType {
  isProjectLimitModalOpen: boolean
  openProjectLimitModal: () => void
  closeProjectLimitModal: () => void
}

const ProjectLimitModalContext = createContext<ProjectLimitModalContextType>({
  isProjectLimitModalOpen: false,
  openProjectLimitModal: () => {
    console.error('openProjectLimitModal function called before being initialized')
  },
  closeProjectLimitModal: () => {
    console.error('closeProjectLimitModal function called before being initialized')
  },
})

export const useProjectLimitModalContext = (): ProjectLimitModalContextType => {
  return useContext(ProjectLimitModalContext)
}

export const ProjectLimitModalContextProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isProjectLimitModalOpen, setIsProjectLimitModalOpen] = useState<boolean>(false)

  const openProjectLimitModal = useCallback(() => {
    setIsProjectLimitModalOpen(true)
  }, [])

  const closeProjectLimitModal = useCallback(() => {
    setIsProjectLimitModalOpen(false)
  }, [])

  return (
    <ProjectLimitModalContext.Provider
      value={{
        isProjectLimitModalOpen,
        openProjectLimitModal,
        closeProjectLimitModal,
      }}
    >
      {children}
    </ProjectLimitModalContext.Provider>
  )
}
