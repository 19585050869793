import React, { FC, useEffect } from 'react'
import OnboardingStepTemplate from '../OnboardingStepTemplate'
import { ONBOARDING_SKIPPED_CLEANUP_KEY } from '../../../../utils/sessionUtils'

interface Props {
  onNext: () => void
}
const OnboardingSessionsCompleteV3Dialog: FC<Props> = (props) => {
  const { onNext } = props

  useEffect(() => {
    const cleanUpSkipped = localStorage.getItem(ONBOARDING_SKIPPED_CLEANUP_KEY) === 'true'
    if (cleanUpSkipped) {
      onNext()
    }
  }, [onNext])

  return (
    <OnboardingStepTemplate
      phaseNum={4}
      numPhases={6}
      heading={
        <>
          {`Whew! Don't you feel better already? 😌`}
          <br />
          <br />
          {`You now have fewer tabs open, and everything is safely saved and neatly organized.`}
        </>
      }
      buttonText={`I like it!`}
      onButtonClick={onNext}
      delayButtonActivation={false}
    />
  )
}
export default OnboardingSessionsCompleteV3Dialog
