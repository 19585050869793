import React, { FC } from 'react'
import GenericModal from '../../../widgets/GenericModal'
import { APP_NAME } from '../../../constants'

interface Props {
  isOpen: boolean
  onCancel: () => void
  onSubmit: () => void
}

const ProjectLimitModal: FC<Props> = (props) => {
  const { isOpen, onCancel, onSubmit } = props

  return (
    <GenericModal
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={onSubmit}
      headingText={`Organizing skills need more space! 🧠✨`}
      bodyText={
        <>
          {APP_NAME} Basic has helped you create 10 Folders (that&apos;s amazing!).
          <br />
          <br />
          Upgrade to Pro for up to 100 Folders to organize in sync with your natural thinking.
        </>
      }
      cancelButtonText="Dismiss"
      submitButtonText="Upgrade"
    />
  )
}

export default ProjectLimitModal
