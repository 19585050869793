import React, { FC, useEffect, useMemo } from 'react'
import OnboardingStepTemplate from '../OnboardingStepTemplate'
import { Box, Text } from '@chakra-ui/react'
import { styled } from 'styled-components'
import { useReduxSelector } from '../../../../redux/baseStore'
import { selectSessionSessions } from '../../../../redux/sessionSlice'
import { ONBOARDING_ORGANIZED_SESSION_ID_KEY } from '../../../../utils/sessionUtils'

const Container = styled(Box)`
  .large-text,
  .large-text * {
    font-size: 28px;
    font-weight: 500;
    line-height: 40px;
    color: #000;
    letter-spacing: -1px;

    @media (max-width: 1200px) {
      /* Screens narrower than 1200px */
      font-size: 24px;
      line-height: 36px;
    }
  }
`

interface Props {
  onNext: () => void
}
const OnboardingSessionsOrganizeV3Dialog: FC<Props> = (props) => {
  const { onNext } = props
  const sessions = useReduxSelector(selectSessionSessions)

  const mostImportant = useMemo(() => {
    if (!sessions || sessions.length === 0) {
      return undefined
    }

    const sessionsWithActiveTabs = sessions.map((session) => {
      const activeTabsCount = session.pages.filter((page) => page.is_open).length
      return { session, activeTabsCount }
    })

    sessionsWithActiveTabs.sort((a, b) => {
      if (b.activeTabsCount === a.activeTabsCount) {
        return b.session.datetime_start_ts - a.session.datetime_start_ts
      }
      return b.activeTabsCount - a.activeTabsCount
    })

    // Ensure the session with less than 10 tabs is prioritized to avoid paywall
    const sessionToSuggest = sessionsWithActiveTabs.find(({ session }) => session.pages.length < 10)

    return sessionToSuggest ? sessionToSuggest.session : sessionsWithActiveTabs[0].session
  }, [sessions])

  useEffect(() => {
    if (mostImportant) {
      localStorage.setItem(ONBOARDING_ORGANIZED_SESSION_ID_KEY, mostImportant.id.toString())
    }
  }, [mostImportant])

  return (
    <OnboardingStepTemplate
      phaseNum={4}
      numPhases={6}
      bodyContent={
        <Container>
          <Text className="large-text" mb="4px">
            This &quot;{mostImportant?.name}&quot; session seems important.
          </Text>
          <Text mt="4" className="large-text" mb="4px">
            Let&apos;s add it to your Folders list on the left so you can easily revisit it and
            share it with others.
          </Text>
          <Text mt="4" className="large-text" mb="4px">
            Drag and drop it on New Folder 👈
          </Text>
        </Container>
      }
      buttonText={`That was magic! ✨`}
      onButtonClick={onNext}
      delayButtonActivation={false}
    />
  )
}

export default OnboardingSessionsOrganizeV3Dialog
