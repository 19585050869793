import React, { FC, useCallback, useEffect } from 'react'
import { useReduxDispatch } from '../../../redux/baseStore'
import { useReduxSelector } from '../../../redux/baseStore'
import { Flex, Text, IconButton, Tooltip } from '@chakra-ui/react'
import { MdArrowOutward, MdClose, MdOpenInNew } from 'react-icons/md'
import { useUserContext } from '../../../contexts/UserContext'
import {
  clearSelectedPages,
  selectAllSelectedPages,
  selectNumSelectedPages,
  selectSelectedProjectPages,
  selectSelectedSavedForLaterPages,
  selectSelectedSmartSessionPages,
} from '../../../redux/selectedPagesSlice'
import { sendMessageToExtension } from '../../../webapp/utils/externalMessaging'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import { shouldOpenInNewTab } from '../../../utils/utils'
import { useExtensionHealthContext } from '../../../contexts/ExtensionHealthContext'
import {
  addPendingFolderCreationPages,
  selectIsFolderCreationModeActive,
  selectPendingFolderCreationPages,
} from '../../../redux/projectsSlice'
import { TablistPageType } from '../../../models/tablist_pages.types'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'

const MultiSelectToolbar: FC = () => {
  const { captureAnalytics, userInfo } = useUserContext()
  const dispatch = useReduxDispatch()
  const { extensionHealth } = useExtensionHealthContext()
  const isExtensionVersion84OrHigher = extensionHealth?.version && extensionHealth.version >= 84
  const isFolderCreationModeActive = useReduxSelector(selectIsFolderCreationModeActive)
  const selectedSavedForLaterPages = useReduxSelector(selectSelectedSavedForLaterPages)
  const selectedSmartSessionPages = useReduxSelector(selectSelectedSmartSessionPages)
  const selectedProjectPages = useReduxSelector(selectSelectedProjectPages)

  const numSelectedSavedForLaterPages = Object.keys(selectedSavedForLaterPages).length
  const numSelectedSmartSessionPages = Object.keys(selectedSmartSessionPages).length
  const numSelectedProjectPages = Object.keys(selectedProjectPages).length

  const allSelectedPages = useReduxSelector(selectAllSelectedPages)
  const numSelectedPages = useReduxSelector(selectNumSelectedPages)

  const isSelectionActive = numSelectedPages > 0

  const pendingPages = useReduxSelector(selectPendingFolderCreationPages)
  const numPendingPages = pendingPages.length
  const isPremium = !!userInfo?.is_premium
  const { projectConfig } = useFeatureFlagContext()
  const maxNumPages = projectConfig.maxNumProjectsPages
  const isProjectPageLimitReached = numPendingPages >= maxNumPages

  const handleOpen = useCallback(
    async (params: { newWindow: boolean; logAction: string; shouldActivate: boolean }) => {
      const { newWindow, logAction, shouldActivate } = params
      const pages = Object.values(allSelectedPages)
      const urls = pages.sort((a, b) => a.order - b.order).map((page) => page.url)

      captureAnalytics(`multi_select_toolbar:${logAction}`, {
        urls,
        newWindow,
        shouldActivate,
        numSelectedSavedForLaterPages,
        numSelectedSmartSessionPages,
        numSelectedProjectPages,
        numSelectedPages,
      })

      const hasOpenMetadata = pages.every((p) => 'is_open' in p)
      if (hasOpenMetadata && isExtensionVersion84OrHigher) {
        const pagesWithFixedIds = pages.map((p) => {
          if (typeof p.id !== 'string') {
            // TODO: Fix TablistPages model so this isn't needed (or change typescript definition to match)
            return p
          }
          //Convert "<session_id>-<page_id>" to just "<page_id>" for use in matching open tabs
          const idParts = p.id.split('-')
          const id = idParts.length > 1 ? idParts[1] : idParts[0]
          return { ...p, id }
        })
        await sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.OPEN_TABLIST_PAGES, {
          pages: pagesWithFixedIds,
          newWindow,
          shouldActivate,
        })
      } else {
        await sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.OPEN_TABS, {
          urls,
          newWindow,
          shouldActivate,
        })
      }
    },
    [
      allSelectedPages,
      captureAnalytics,
      numSelectedSavedForLaterPages,
      numSelectedSmartSessionPages,
      numSelectedProjectPages,
      numSelectedPages,
      isExtensionVersion84OrHigher,
    ],
  )

  const handleClickOpenInNewWindowIcon = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      await handleOpen({
        newWindow: true,
        logAction: 'open_in_new_window_click',
        shouldActivate: !shouldOpenInNewTab(event),
      })
      dispatch(clearSelectedPages())
    },
    [handleOpen, dispatch],
  )

  const handleClickOpenIcon = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      await handleOpen({
        newWindow: false,
        logAction: 'open_click',
        shouldActivate: !shouldOpenInNewTab(event),
      })
      dispatch(clearSelectedPages())
    },
    [handleOpen, dispatch],
  )

  const handleClickClearSelectionIcon = useCallback(() => {
    captureAnalytics('multi_select_toolbar:clear_selection_click', {
      numSelectedSavedForLaterPages,
      numSelectedSmartSessionPages,
      numSelectedProjectPages,
      numSelectedPages,
    })
    dispatch(clearSelectedPages())
  }, [
    numSelectedSavedForLaterPages,
    numSelectedSmartSessionPages,
    numSelectedProjectPages,
    numSelectedPages,
    captureAnalytics,
    dispatch,
  ])

  const handleClickFolderCreationModeAddAll = useCallback(() => {
    // Currently folder creation mode will prevent multiselect with project pages, but we should still support them here...
    // TODO: Properly convert ProjectPageType to TablistPageType
    const pages = Object.values(allSelectedPages) as TablistPageType[]

    captureAnalytics(`multi_select_toolbar:folder_creation_mode_add_all_click`, {
      numPages: pages.length,
    })

    dispatch(addPendingFolderCreationPages({ pages, index: 0 }))
    dispatch(clearSelectedPages())
  }, [captureAnalytics, dispatch, allSelectedPages])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!isSelectionActive) {
        return
      }

      if (event.key === 'Escape') {
        dispatch(clearSelectedPages())
      } else if (event.key === 'Enter') {
        handleOpen({
          newWindow: false,
          logAction: 'open_with_enter_press',
          shouldActivate: !shouldOpenInNewTab(event),
        })
        dispatch(clearSelectedPages())
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [numSelectedPages, dispatch, handleOpen, isSelectionActive])

  return (
    <Flex
      w="100%"
      h={isSelectionActive ? '38px' : '0px'}
      p={isSelectionActive ? '8px 16px' : '0px'}
      opacity={isSelectionActive ? 1 : 0}
      transition="opacity 0.25s ease-in-out"
      overflow="hidden"
      borderRadius="32px"
      background="#E0E0E0"
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex id="toolbar-left-side" gap="8px" alignItems="center">
        <Tooltip placement="top" label="Clear selection" isDisabled={!isSelectionActive}>
          <IconButton
            variant="outline"
            border="none"
            aria-label="Clear selection"
            as="a"
            icon={<MdClose color="#585858" />}
            onClick={handleClickClearSelectionIcon}
            height="24px"
            width="24px"
            minWidth="24px"
            tabIndex={-1}
            _hover={{ background: 'inherit' }}
          />
        </Tooltip>
        <Text fontSize="14px" color="#585858" fontWeight="500" lineHeight="22px">
          {`${numSelectedPages} selected`}
        </Text>
      </Flex>
      <Flex id="toolbar-right-side">
        {isFolderCreationModeActive && (
          <Tooltip
            placement="top"
            label={
              isProjectPageLimitReached ? (
                <>
                  Tab limit reached!
                  <br />
                  {isPremium ? 'Pro' : 'Basic'} folders hold {maxNumPages} tabs (need more space?)
                  ✨
                </>
              ) : (
                'Add all tabs to folder'
              )
            }
          >
            <Flex
              bg="#CCE3F9"
              borderRadius="6px"
              border="1px solid #0071e377"
              color="#0071E3"
              w="67px"
              h="22px"
              flexShrink={0}
              alignItems="center"
              justifyContent="center"
              onClick={isProjectPageLimitReached ? undefined : handleClickFolderCreationModeAddAll}
              cursor={isProjectPageLimitReached ? 'not-allowed' : 'pointer'}
              opacity={isProjectPageLimitReached ? 0.4 : 1}
              mr="-8px"
            >
              <Text fontSize="12px" fontWeight="500">
                + Add all
              </Text>
            </Flex>
          </Tooltip>
        )}

        {!isFolderCreationModeActive && (
          <>
            <Tooltip label={`Open in new window`} placement="top" isDisabled={!isSelectionActive}>
              <IconButton
                variant="outline"
                border="none"
                aria-label={`Open in new window`}
                as="a"
                icon={<MdOpenInNew color="#585858" />}
                onClick={handleClickOpenInNewWindowIcon}
                height="24px"
                width="24px"
                minWidth="24px"
                tabIndex={-1}
                _hover={{ background: 'inherit' }}
              />
            </Tooltip>
            <Tooltip label={`Open`} placement="top" isDisabled={!isSelectionActive}>
              <IconButton
                variant="outline"
                border="none"
                aria-label={`Open`}
                as="a"
                icon={<MdArrowOutward color="#585858" />}
                onClick={handleClickOpenIcon}
                height="24px"
                width="24px"
                minWidth="24px"
                tabIndex={-1}
                _hover={{ background: 'inherit' }}
              />
            </Tooltip>
          </>
        )}
      </Flex>
    </Flex>
  )
}

export default MultiSelectToolbar
